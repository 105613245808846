import { Helmet } from 'react-helmet-async';
import {PageHeader} from "../../../../components/PageHeader";
import CountryListDataTable from "./CountryListDataTable";

const CountryList = () => {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]
    const headerButton =
        {
            href: '/countries/create',
            title: 'Country Create',
        }
    return (
        <>
            <Helmet>
                <title>Country List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} showButton={true} title="Country List" headerButton={headerButton}/>
            <CountryListDataTable/>

        </>
    );
}

export default CountryList;
