import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Grid,
    CircularProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {CustomerProduct, CustomerProductInput} from "../../../types/Customer";
import {FormSubmit} from "../../../types/Form";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useState} from "react";
import {get} from "../../../services/ApiService";
import {CountrySelectList} from "../../../types/zone";
type CustomerProductEditFormProps = {
    product: CustomerProduct,
    handleSubmit: FormSubmit
}


const CForm = (props: FormikProps<CustomerProductInput>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props;
    const [countries, setCountries] = useState<CountrySelectList[]>([]);
    useEffect(() => {
        get('admin/countries-select-data').then((res) => {
            setCountries(res.data);
        })
    }, [])
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            label="Name"
                            value={values.name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            fullWidth
                            label="Country"
                            name="country_id"
                            value={countries.length > 0 ? values.country_id : ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            helperText={touched.country_id ? errors.country_id : ""}
                            error={touched.country_id && Boolean(errors.country_id)}
                        >
                            {countries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            id="api_key"
                            label="Api Key"
                            value={values.api_key || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.api_key ? errors.api_key : ""}
                            error={touched.api_key && Boolean(errors.api_key)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                            {isSubmitting && <CircularProgress size={20}/>}
                            {!isSubmitting && 'Update'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const CustomerProductEditForm = (props: CustomerProductEditFormProps) => {
    const { handleSubmit, product } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({ name: product.name, api_key: product.api_key, country_id: product.country_id }),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            api_key: Yup.string().required("Required"),
            country_id: Yup.string().required("Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default CustomerProductEditForm;