import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AbcIcon from '@mui/icons-material/Abc';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from "react";
import {ActionButtonProps, DataTableAction} from "../type";
import {useNavigate} from "react-router";
import {del} from "../../../services/ApiService";
import {post} from "../../../services/ApiService";
import { Payment } from "@mui/icons-material";


const ActionButton = ({actions, id, module, reFetchData}: ActionButtonProps) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [currentAction, setCurrentAction] = React.useState({type: '', to: ''});

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgree = () => {
        const href = currentAction.to.replace(':id', id);
        post(href, {}).then((res) => {
            setOpen(false);
            window.location.reload();
            reFetchData('deleted')
            
        })
    };
    const handelClick = (action: DataTableAction): void => {
        if(action.type === 'delete') {
            setOpen(true);
            setCurrentAction(action);
        }else {
            const href = action.to.replace(':id', id);
            navigate(href)
        }
    }
    const getIconByType = (type: string): JSX.Element => {
        let icon;
        switch (type) {
            case 'edit':
                icon = <EditIcon/>;
                break;
            case 'delete':
                icon = <DeleteIcon/>;
                break;
            case 'subscription':
                icon = <Payment/>;
                break;
            default:
                icon = <AbcIcon/>;
        }
        return icon;
    }

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are You Sure want to delete {(module ?? '')} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleAgree} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                {actions.map((action, index) => {
                    return ( <IconButton
                        aria-label={action.type}
                        key={index}
                        onClick={() => {
                            handelClick(action)
                        }}>
                        {getIconByType(action.type)}
                    </IconButton>)
                })}

            </div>
        );
}
export default ActionButton;