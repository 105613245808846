import { Helmet } from 'react-helmet-async';
import { PageHeader} from "../../../components/PageHeader";
import BlogListDataTable from './BlogListDataTable';

function BlogList() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]

    const headerButton =
        {
            href: '/blog/create',
            title: 'Add Blog',
        }
    return (
        <>
            <Helmet>
                <title>Blog List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Blog List" showButton={true} headerButton={headerButton}/>
            <BlogListDataTable />
        </>
    );
}
export default BlogList;
