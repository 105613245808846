import { Button, Card, Container, Grid, Switch } from '@mui/material';
import DataTable from "../../../components/DataTable";
import ActionButton from "../../../components/DataTable/ActionButton";
import { DataTableAction } from "../../../components/DataTable/type";
import { BlogData } from 'src/types/Blog';
import { useState } from 'react';
import { post } from 'src/services/ApiService';
import { Navigate, useNavigate } from 'react-router';
import { notify } from 'src/services/GlobalService';

function BlogListDataTable() {
    const actions: DataTableAction[] = [{ type: 'edit', to: '/blog/:id/edit' }, { type: 'delete', to: 'admin/delete/blog/:id' }];
    const reFetchData = (type: string) => {
        const newParams = params.map((param) => {
            if (param.name === '__i') {
                param.value = (parseInt(param.value) + 1) + '';
            }
            return param;
        })
        setParams(newParams);
    }
    const columns = [
        { title: 'Id', field: 'id', width: '10%' },
        { title: 'Title', field: 'title' },
        { title: 'Slug', field: 'slug', width: '30%' },
        { title: 'Short Description', field: 'short_desc' },
        { title: 'Posted By', field: 'posted_by' },
        {
            title: 'Action',
            render: (rowData: BlogData) => {
                return (<ActionButton reFetchData={reFetchData} actions={actions} id={rowData.slug} />);
            }
        },
        {
            title: 'Change Status', render: (rowData: BlogData) => {
                return (<Switch color='primary' onChange={() => changeStatus(rowData)} defaultChecked={!!rowData.status} />
                );
            }
        }
    ]
    const url = 'admin/blogs';
    const [params, setParams] = useState([{ name: '__i', value: '1' }])
    const navigate = useNavigate();

    const changeStatus = (data: any) => {
        var status = 1
        if (data.status == 1)
            status = 0
        post('admin/update/blog-status/' + data.slug, {status}).then((res) => {
            navigate('/blogs');
            notify(res.data.message)
        }).catch((error) => {

        });
    }

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default BlogListDataTable;
