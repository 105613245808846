import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Grid,
    CircularProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {FormSubmit} from "../../../types/Form";
import {CustomerProductSettingFeature} from "../../../types/Customer";
type CustomerProductSettingFormProps = {
    handleSubmit: FormSubmit,
    setting: CustomerProductSettingFeature,
}
const CForm = (props: FormikProps<CustomerProductSettingFeature>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            label="Name"
                            value={values.name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="ref"
                            label="Reference"
                            value={values.ref || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.ref ? errors.ref : ""}
                            error={touched.ref && Boolean(errors.ref)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="info"
                            label="Small Info"
                            value={values.info || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.info ? errors.info : ""}
                            error={touched.info && Boolean(errors.info)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="icon"
                            label="Icon Name"
                            value={values.icon || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.icon ? errors.icon : ""}
                            error={touched.icon && Boolean(errors.icon)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                            {isSubmitting && <CircularProgress size={20}/>}
                            {!isSubmitting && 'Update'}
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </div>
    );
};
const CustomerProductSettingForm = (props: CustomerProductSettingFormProps) => {
    const { handleSubmit, setting } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => (setting),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            ref: Yup.string().required("Required"),
            info: Yup.string().required("Required"),
            icon: Yup.string().required("Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors);
        },
    })(CForm);
    return <FormElement/>
}

export default CustomerProductSettingForm;