import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import { showValidationError } from 'src/services/FormService';
import { get, post } from 'src/services/ApiService';
import { FormSetError, FormSubmitting } from 'src/types/Form';
import { PageHeader } from 'src/components/PageHeader';
import { UpcomingUpdatesData } from 'src/types/UpcomingUpdates';
import UpcomingUpdatesCreateForm from '../UpComingUpdatesCreate/UpcomingUpdatesCreateForm';

const defaultInitialValues: UpcomingUpdatesData = {
    id: '',
    title: '',
    description: '',
}
function UpComingUpdatesEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/upcoming-updates',
            title: 'Upcoming Updates List',
        }
    ]

    useEffect(() => {
        get('admin/upcoming-update/' + id).then((res) => {
            setInitialValues(res.data.data);
        })
    }, [])

    const handleSubmit = (data: UpcomingUpdatesData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        const formData = new FormData()
        formData.append('id', data.id)
        formData.append('title', data.title)
        formData.append('description', data.description)
        post('admin/update/upcoming-update/' + id, data).then(() => {
            setSubmitting(false);
            navigate('/upcoming-updates')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Blog Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Upcoming Update Edit" showButton={false}/>
            <UpcomingUpdatesCreateForm handleSubmit={handleSubmit} initialValues={initialValues}/>
        </>
    );
}

export default UpComingUpdatesEdit;
