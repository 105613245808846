import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {FormSubmit} from "../../../../types/Form";
import {
    CountrySelectList,
    LocationData,
    LocationInputData,
    StateData,
    StateSelectList,
} from "../../../../types/zone";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect, useState} from "react";
import {get} from "../../../../services/ApiService";
import GoogleMapPolygon from "../../../../components/GoogleMapPolygon";
import GoogleAutoComplete from "../../../../components/GoogleAutoComplete";
import {AxiosResponse} from "axios";

type AreaCreateFormProp = {
    handleSubmit: FormSubmit<LocationInputData>;
    initialValues?: LocationData
}

const CForm = (props: FormikProps<LocationInputData>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    const countryData: CountrySelectList[] = []
    const [countries, setCountries] = useState(countryData);
    const [states, setStates] = useState<StateSelectList[]>([]);
    const types = [
        {label: 'Airport', value: 'airport'}
    ];
    const [position, setPosition] = useState({lat: 0, lng: 0, zoom: 0});
    const [place, setPlace] = useState({name: '', lat: 0, lng: 0})
    useEffect(() => {
        get('admin/countries-select-data').then((res) => {
            setCountries(res.data);
            if (values.country_id) {
                populateStates(values.country_id);
            }
        })
    }, [])
    const countrySelected = (country: CountrySelectList) => {
        setPosition(country);
        populateStates(country.value);
    }
    const populateStates = (countryId: string) => {
        get('admin/states-select-data/' + countryId).then((res) => {
            setStates(res.data);
        })
    }
    const stateSelected = (state: StateSelectList) => {
        get('admin/states/' + state.value).then((res: AxiosResponse<StateData>) => {
            console.log(res.data);
        })
    }
    const handelPlaceSelection = (placeData: {name: string, lat: number, lng: number}) => {
        setFieldValue('name', placeData.name);
        setPlace(placeData);
    }
    const onPolySelected = (polygonString: string, boundBoxString: string) => {
        setFieldValue('polygon_string', polygonString);
        setFieldValue('boundingbox', boundBoxString);
    }

    return (
        <div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title={values.polygon_data ? 'Location Edit Form' : 'Location Create Form 2'} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} >
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            fullWidth
                                            label="Country"
                                            name="country_id"
                                            value={countries.length > 0 ? values.country_id : ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.country_id ? errors.country_id : ""}
                                            error={touched.country_id && Boolean(errors.country_id)}
                                        >
                                            {countries.map((option) => (
                                                <MenuItem key={option.value} value={option.value}
                                                          onClick={() => countrySelected(option)}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {/* <Grid item xs={4} >
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            fullWidth
                                            label="State"
                                            name="state_id"
                                            value={states.length > 0 ? values.state_id : ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.state_id ? errors.state_id : ""}
                                            error={touched.state_id && Boolean(errors.state_id)}
                                        >
                                            {states.map((option) => (
                                                <MenuItem key={option.value} value={option.value}
                                                          onClick={() => stateSelected(option)}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid> */}
                                    <Grid item xs={4} >
                                        <GoogleAutoComplete onSelect={handelPlaceSelection}
                                                            helperText={touched.name ? errors.name : ""}
                                                            label="Location Name"
                                                            stateNameValue={values.name}
                                                            error={touched.name && Boolean(errors.name)} />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            id="type"
                                            select
                                            fullWidth
                                            label="Type"
                                            name="type"
                                            value={ values.type || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.type ? errors.type : ""}
                                            error={touched.type && Boolean(errors.type)}
                                        >
                                            {types.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            id="type"
                                            fullWidth
                                            label="Code"
                                            name="code"
                                            value={ values.code || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.code ? errors.code : ""}
                                            error={touched.code && Boolean(errors.code)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <GoogleMapPolygon place={place}
                                                          position={position}
                                                          polygon={values.polygon_data}
                                                          onPolySelected={onPolySelected} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            id="polygon_string"
                                            type="hidden"
                                            fullWidth
                                            name="polygon_string"
                                            value={values.polygon_string}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.name ? errors.polygon_string : ""}
                                            error={touched.name && Boolean(errors.polygon_string)}
                                        />

                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting  && <CircularProgress size={20} />}
                                            {!isSubmitting  && (values.polygon_data ? 'Edit' : 'Create')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const LocationCreateForm = (props: AreaCreateFormProp) => {
    const { handleSubmit, initialValues } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({
            name: initialValues?.name ?? '',
            state_id: initialValues?.state_id ?? '',
            type: initialValues?.type ?? 'airport',
            code: initialValues?.code ?? '',
            country_id: initialValues?.country_id ?? '',
            polygon_data: initialValues?.polygon_data ?? null,
            name_search: '',
            polygon_string: '',
            boundingbox: ''}),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
            code: Yup.string().required("Required"),
            polygon_string: Yup.string().required("Polygon Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default LocationCreateForm;