import {
    Box,
    Button, ButtonGroup,
    Card,
    CardContent, Checkbox, Collapse,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText, Switch,
    Typography
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CircularProgress from '@mui/material/CircularProgress';
import Text from "../../../components/Text";
import {CustomerProduct, ProductService, ProductServiceFeature} from "../../../types/Customer";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import {post} from "../../../services/ApiService";
import {AxiosResponse} from "axios";
import {ProductConfig} from "./index";

const CustomerProductDetail = ({product, services, config}: { product: CustomerProduct, services: ProductService[], config: ProductConfig }) => {
    const preChecked = product?.services.map(productService => productService.reference);
    const preCheckedFeatures = product?.features.map(productFeature => productFeature.service_reference + '-' + productFeature.reference);
    const [serviceChecked, setServiceChecked] = useState<string[]>(preChecked);
    const [featureChecked, setFeatureChecked] = useState<string[]>(preCheckedFeatures);
    const [currentLoader, setCurrentLoader] = useState('');


    const handleServiceToggle = (service: ProductService) =>  {
        setCurrentLoader(service.reference);
        post('admin/products/' + product.id + '/toggle-service', {service: service.reference}).then((res: AxiosResponse<{ status: boolean }>) => {
            if(res.data.status) {
                setCurrentLoader('');
                const currentIndex = serviceChecked.indexOf(service.reference);
                const newChecked = [...serviceChecked];

                if (currentIndex === -1) {
                    newChecked.push(service.reference);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                setServiceChecked(newChecked);
            }
        }).catch(() => {
            setCurrentLoader('');
        })

    };
    const handleFeatureToggle = (feature: ProductServiceFeature, service: ProductService) =>  {

        const ref = service.reference + '-' + feature.reference;
        setCurrentLoader(ref);
        post('admin/products/' + product.id + '/toggle-feature', {service: service.reference, feature: feature.reference}).then((res: AxiosResponse<{ status: boolean }>) => {
            if(res.data.status) {
                setCurrentLoader('');
                const currentIndex = featureChecked.indexOf(ref);
                const newChecked = [...featureChecked];

                if (currentIndex === -1) {
                    newChecked.push(ref);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                setFeatureChecked(newChecked);
            }
        }).catch(() => {
            setCurrentLoader('');
        })
    };
    return (
        <Grid container>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                <Card>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                Product Detail
                            </Typography>
                            <Typography variant="subtitle2">
                                Manage details related to your Product
                            </Typography>
                        </Box>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button variant="text" startIcon={<EditTwoToneIcon/>} component={NavLink}
                                    to={'/customers/' + product?.customer_id + '/product-edit'}>
                                Edit
                            </Button>
                            {serviceChecked.indexOf('setting') !== -1 && <Button variant="text" startIcon={<EditTwoToneIcon/>} component={NavLink}
                                     to={'/products/' + product.id + '/setting'}>
                                Manage Setting Module
                            </Button>}
                        </ButtonGroup>

                    </Box>
                    <Divider/>
                    <CardContent sx={{p: 4}}>
                        <Typography variant="subtitle2">
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                    <Box pr={3} pb={2}>
                                        Name:
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8} md={9}>
                                    <Text color="black">
                                        <b>{product.name}</b>
                                    </Text>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                    <Box pr={3} pb={2}>
                                        Api Key:
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8} md={9}>
                                    <Text color="black">
                                        <b>{product.api_key}</b>
                                    </Text>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                    <Box pr={3} pb={2}>
                                        Country:
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8} md={9}>
                                    <Text color="black">
                                        <b>{product.country_name}</b>
                                    </Text>
                                </Grid>
                            </Grid>
                        </Typography>
                        <Box  p={3}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Configs
                                    <Typography variant="subtitle2">
                                        Manage Config related to your Product
                                    </Typography>
                                </Typography>

                                <Button variant="text" startIcon={<EditTwoToneIcon/>} component={NavLink}
                                        to={'/products/' + product?.id + '/config-edit'}>
                                    Edit
                                </Button>
                            </Box>
                                <Divider/>

                                <Box sx={{mt:5}}>
                                    <Grid container spacing={2}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                                <Box pr={3} pb={2}>
                                                    Distance Unit:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{config.distance_unit}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                                <Box pr={3} pb={2}>
                                                    Currency:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{config.currency}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'right'}}>
                                                <Box pr={3} pb={2}>
                                                    Accessed Countries:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{config.accessed_countries}</b>
                                                </Text>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Box>



                        <Divider sx={{mt:5}}/>
                        <Box sx={{mt:5}}>
                            <Typography variant="h4" gutterBottom>
                                Modules List
                            </Typography>
                            <Typography variant="subtitle2">
                                Manage Module related to your Product
                            </Typography>
                            <Box>
                                <Grid container spacing={2}>

                                    {services.map((service, index) => {
                                        return <Grid item xs={6} key={index}>
                                            <List
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    mb: 1,
                                                    border: '1px solid #ddd',
                                                    borderRadius: '5px'
                                                }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItem>
                                                    <ListItemText id={'switch-list-label-' + service.reference}
                                                                  primary={service.name} secondary={service.info}/>
                                                    {currentLoader !== service.reference && <Switch
                                                        edge="end"
                                                        onChange={() => handleServiceToggle(service)}
                                                        checked={serviceChecked.indexOf(service.reference) !== -1}
                                                        inputProps={{
                                                            'aria-labelledby': 'switch-list-label-' + service.reference,
                                                        }}
                                                    />}
                                                    { currentLoader === service.reference && <CircularProgress  color="inherit" />}
                                                </ListItem>

                                                <Collapse in={serviceChecked.indexOf(service.reference) !== -1} timeout="auto"
                                                          unmountOnExit>

                                                    <List sx={{borderTop: '1px solid #ddd'}} component="div"
                                                          disablePadding>
                                                        {service.features.map((feature, featureIndex) => {
                                                            return <ListItem key={featureIndex}>
                                                                <ListItemText
                                                                    id={'check-list-label-' + service.reference + '-' + feature.reference}
                                                                    primary={feature.name} secondary={feature.info}/>
                                                                { currentLoader !== (service.reference + '-' + feature.reference) &&
                                                                    <Checkbox
                                                                        edge="end"
                                                                        onChange={() => handleFeatureToggle(feature, service)}
                                                                        checked={featureChecked.indexOf(service.reference + '-' + feature.reference) !== -1}
                                                                        inputProps={{
                                                                            'aria-labelledby': 'check-list-label-' + service.reference + '-' + feature.reference,
                                                                        }}
                                                                    />
                                                                }
                                                                { currentLoader === (service.reference + '-' + feature.reference) && <CircularProgress size="1rem"  color="inherit" />}
                                                            </ListItem>
                                                        })}

                                                    </List>
                                                </Collapse>
                                            </List>
                                        </Grid>
                                    })}
                                </Grid>

                            </Box>
                        </Box>
                        <Divider/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CustomerProductDetail;