import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    styled,
    Typography
} from '@mui/material';
import {CustomerDatabaseInfoProps} from "./types";
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as React from "react";
import DialogAlert from "../../../components/DIalogAlert";
const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      box-shadow: ${theme.colors.shadows.success};
`
);
const CustomerDatabaseInfo = (customerDatabaseInfoProps: CustomerDatabaseInfoProps) => {
    const data = customerDatabaseInfoProps.databaseInfo;
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleAgree = () => {
        customerDatabaseInfoProps.onMigrationClick( setOpen, setLoading);
    };
    return (
        <Grid container spacing={2} pt={2} sx={{minHeight: '62vh'}}>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                <Card>
                    <CardHeader title="Database Migration Status"/>
                    <Divider/>
                    <CardContent>
                      <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box
                                    display="flex"
                                    sx={{
                                        py: 4
                                    }}
                                    alignItems="center"
                                >
                                    <AvatarSuccess
                                        sx={{
                                            mr: 2
                                        }}
                                        variant="rounded"
                                    >
                                        <CheckBoxIcon fontSize="large"/>
                                    </AvatarSuccess>
                                    <Box>
                                        <Typography variant="h4">{data?.done}</Typography>
                                        <Typography variant="subtitle2" noWrap>
                                            Total Migrated
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    display="flex"
                                    sx={{
                                        py: 4
                                    }}
                                    alignItems="center"
                                >
                                    <AvatarSuccess
                                        sx={{
                                            mr: 2
                                        }}
                                        variant="rounded"
                                    >
                                        <ListAltIcon fontSize="large"/>
                                    </AvatarSuccess>
                                    <Box>
                                        <Typography variant="h4">{data?.total}</Typography>
                                        <Typography variant="subtitle2" noWrap>
                                            Total Migrations
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                {data?.status &&
                                    <Button fullWidth onClick={handleOpen} variant="contained">
                                    Migrate Customer Database
                                </Button>}
                            </Grid>
                            <DialogAlert
                                status={open}
                                loading={loading}
                                title={'Migrate?'}
                                info={'Are you sure want to migrate database?'}
                                handleOK={handleAgree}
                                handleCancel={handleClose}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CustomerDatabaseInfo;