import { Helmet } from 'react-helmet-async';
import { HeaderButton, PageHeader } from "../../../components/PageHeader";
import { useEffect, useState } from "react";
import { get } from "../../../services/ApiService";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function CustomerSubscription() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }

    ]
    const headerButton =
    {
        href: '/customers/create',
        title: 'Customer Create',
    }
    const defaultExtraButton: HeaderButton = null;
    const [extraButton, setExtraButton] = useState(defaultExtraButton)
    const [id, setId] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    useEffect(() => {
        const url = window.location.href;
        const parts = url.split('/');

        const index = parts.indexOf('subscription');

        if (index !== -1 && !isNaN(parseInt(parts[index + 1]))) {
            const subscriptionId = parseInt(parts[index + 1]);
            setId(subscriptionId);

            get('admin/customers/individual-suscription/' + subscriptionId).then((res) => {
                setSubscriptions(res.data.data);
            }).catch(error => {
                console.error('Error fetching customer data:', error);
            });
        }
    }, []);





    return (
        <>
            <Helmet>
                <title>Customer List</title>
                <link
                    rel="stylesheet"
                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
                    integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z"
                    crossOrigin="anonymous"
                />
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Customer Suscription" showButton={true} headerButton={headerButton} extraButton={extraButton} />
            <TableContainer component={Paper} style={{ padding: 50 }}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Start At</TableCell>
                            <TableCell align="right">End At</TableCell>
                            <TableCell align="right">Payment</TableCell>
                            <TableCell align="right">Plan</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptions.map(subscription => (
                            <TableRow key={subscription.id}>
                                <TableCell component="th" scope="row">
                                    {subscription.start}
                                </TableCell>
                                <TableCell align="right">{subscription.end}</TableCell>
                                <TableCell align="right">{subscription.currency} {subscription.amount}</TableCell>
                                <TableCell align="right">{subscription.interval}</TableCell>
                                <TableCell align="right">{subscription.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default CustomerSubscription;