import {FormSetError} from "../types/Form";
import {AxiosResponse} from "axios";
type ErrorNameChanges = {
    from: string,
    to: string
}
export const showValidationError = (response: AxiosResponse, setErrors: FormSetError, changes: ErrorNameChanges[] = []): void => {
    if(response.status && response.status == 422) {
        const  errorData = response.data;
        changes.forEach((key) => {
            errorData[key.from] = errorData[key.to];
        })
        setErrors(errorData)
    }
}