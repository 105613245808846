import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {FormSetError, FormSubmitting} from "../../../../types/Form";
import {CountryInputData} from "../../../../types/zone";
import {get, post} from "../../../../services/ApiService";
import {showValidationError} from "../../../../services/FormService";
import {PageHeader} from "../../../../components/PageHeader";
import {useEffect, useState} from "react";
import CountryCreateForm from "../CountryCreate/CountryCreateForm";

const defaultInitialValues = {
    name: '',
    full_name: '',
    google_map_name: '',
    google_map_short_name: '',
    currency: '',
    distance_unit: '',
    zoom_level: 0,
    lat:0,
    lng:0,
    is_edit: true
}
function CountryEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/countries',
            title: 'Country List',
        }
    ]
    useEffect(() => {
        get('admin/countries/' + id).then((res) => {
            setInitialValues(res.data);
        })
    }, [])
    const handleLogin = (data: CountryInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/countries/'+ id, data).then(() => {
            setSubmitting(false);
            navigate('/countries')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Country Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Country Edit" showButton={false}/>
            <CountryCreateForm handleSubmit={handleLogin} initialValues={initialValues} />
        </>
    );
}

export default CountryEdit;
