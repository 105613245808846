import {FieldArray, FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {FormSubmit} from "../../../types/Form";
import {ProductServiceInputs} from "../ProductServiceCreate/types";
import DeleteIcon from '@mui/icons-material/Delete';
type ProductServiceFormProps = {
    handleSubmit: FormSubmit,
    initialValues?: ProductServiceInputs,
    isEdit ?: boolean
}
const CForm = (props: FormikProps<ProductServiceInputs & {isEdit: boolean}>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    const addNewFeature = () => {
        setFieldValue('features', [...values.features, {name: '', info: '', reference: '', sort: (values.features.length + 1)}])
    }
    return (
        <div>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title={'Module ' + (values.isEdit ? 'Edit' : 'Create')  + ' Form'} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="name"
                                            label="Name"
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.name ? errors.name : ""}
                                            error={touched.name && Boolean(errors.name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="reference"
                                            label="Reference"
                                            value={values.reference || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.reference ? errors.reference : ""}
                                            error={touched.reference && Boolean(errors.reference)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="info"
                                            label="Small Info"
                                            value={values.info || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.info ? errors.info : ""}
                                            error={touched.info && Boolean(errors.info)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="sort"
                                            type="number"
                                            label="Order"
                                            value={values.sort || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.sort ? errors.sort : ""}
                                            error={touched.sort && Boolean(errors.sort)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Card>
                                            <CardHeader title="Sub Module List" />
                                            <Divider />
                                            <CardContent>
                                                <FieldArray name='features' render={ arrayHelper => (
                                                    values.features.map((feature, index) => {
                                                            return <Grid container spacing={2} key={index}>
                                                                <Grid item xs={11} >
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={3} >
                                                                            <TextField
                                                                                id={`features[${index}][name]`}
                                                                                label="Name"
                                                                                value={values.features[index].name || ''}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3} >
                                                                            <TextField
                                                                                id={`features[${index}][reference]`}
                                                                                label="Reference"
                                                                                value={values.features[index].reference || ''}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3} >
                                                                            <TextField
                                                                                id={`features[${index}][info]`}
                                                                                label="Small Info"
                                                                                value={values.features[index].info || ''}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3} >
                                                                            <TextField
                                                                                id={`features[${index}][sort]`}
                                                                                type="number"
                                                                                label="Order"
                                                                                value={values.features[index].sort || ''}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={1} >
                                                                    <Button variant="contained"  type="button" sx={{mt: 1}} onClick={() => arrayHelper.remove(index)}>
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        })
                                                )} />



                                                <Grid container spacing={2}>
                                                    <Grid item xs={3} >
                                                        <Button variant="contained" onClick={addNewFeature} type="button" sx={{mt: 1}}>
                                                           Add New Feature
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting  && <CircularProgress size={20} />}
                                            {!isSubmitting  && (values.isEdit ? 'Update' : 'Create')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const ProductServiceForm = (props: ProductServiceFormProps) => {
    const { handleSubmit, initialValues, isEdit } = props;
    const defaultValue = initialValues ?? { name: '', info: '', reference: '', sort: 1 , features: [{name: '', info: '', reference: '', sort: 1}]};
    const FormElement = withFormik({
        mapPropsToValues: () => ({...defaultValue, isEdit: isEdit}),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            info: Yup.string().required("Required"),
            reference: Yup.string().required("Required"),
            sort: Yup.number().required("Required"),
            features: Yup.array()
                .of(Yup.object().shape({
                    name: Yup.string().required("Required"),
                    info: Yup.string().required("Required"),
                    reference: Yup.string().required("Required"),
                    sort: Yup.number().required("Required"),
                }))
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default ProductServiceForm;