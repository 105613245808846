import {
    Box,
    Card,
    CardContent, Checkbox, Collapse, Dialog, DialogContent, DialogTitle,
    Divider,
    Grid,
    List,
    ListItem, ListItemButton,
    ListItemText, Switch,
    Typography
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import {
    CustomerProduct,
    CustomerProductSetting, CustomerProductSettingFeature, ProductService,
    ProductServiceFeature,
    ProductSetting,
    ProductSettingData, ProductSettingFeature
} from "../../../types/Customer";
import {useState} from "react";
import {post} from "../../../services/ApiService";
import {AxiosResponse} from "axios";
import CustomerProductSettingForm from "./CustomerProductSettingForm";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {showValidationError} from "../../../services/FormService";


const CustomerProductSettingDetail = ({product, settings}: { product: CustomerProduct, settings: ProductSetting[] }) => {
    const preChecked = product?.settings.map(productSetting => productSetting.ref);
    const preCheckedFeatures: string [] = [];
    product?.settings.forEach((setting) => {
        setting.features.forEach((feature) => {
            preCheckedFeatures.push(setting.ref + '-' + feature.ref);
        })
    })

    const [currentLoader, setCurrentLoader] = useState('');
    const [currentSetting, setCurrentSetting] = useState<CustomerProductSettingFeature>(null);
    const [settingChecked, setServiceChecked] = useState<string[]>(preChecked);
    const [featureChecked, setFeatureChecked] = useState<string[]>(preCheckedFeatures);
    const [open, setOpen] = useState(false);
    const [productSettings, setProductSettings] = useState(product.settings);

    const handleClose = () => {
        setOpen(false);
    };
    const handleSettingToggle = (setting: ProductSetting) =>  {

        setCurrentLoader(setting.ref);
        post('admin/products/' + product.id + '/toggle-setting', {setting: setting.ref})
            .then((res: AxiosResponse<{ status: boolean, productSettings: CustomerProductSetting[] }>) => {
            if(res.data.status) {
                setCurrentLoader('');
                const currentIndex = settingChecked.indexOf(setting.ref);
                const newChecked = [...settingChecked];

                if (currentIndex === -1) {
                    newChecked.push(setting.ref);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                setServiceChecked(newChecked);
                setProductSettings(res.data.productSettings);
            }
        }).catch(() => {
            setCurrentLoader('');
        })

    };
    const handleFeatureToggle = (feature: ProductSettingFeature, setting: ProductSetting) =>  {
        const ref = setting.ref + '-' + feature.ref;
        setCurrentLoader(ref);
        post('admin/products/' + product.id + '/toggle-setting-feature', { feature_id: feature.id}).then((res: AxiosResponse<{ status: boolean }>) => {
            if(res.data.status) {
                setCurrentLoader('');
                const currentIndex = featureChecked.indexOf(ref);
                const newChecked = [...featureChecked];

                if (currentIndex === -1) {
                    newChecked.push(ref);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                setFeatureChecked(newChecked);
            }
        }).catch(() => {
            setCurrentLoader('');
        })
    };

    const handelSettingClick = (setting: ProductSetting, feature: CustomerProductSettingFeature = null) => {
        let curSetting;
        if(feature) {
             const productObj = productSettings.find(set => set.ref === setting.ref);
             if(productObj) {
                 curSetting =  productObj.features.find(set => set.ref === feature.ref);
             }
        } else {
            curSetting = productSettings.find(set => set.ref === setting.ref);
        }


        if(curSetting) {
            setCurrentSetting(curSetting);
            setOpen(true);
        }
    }

    const handleLogin = (data: ProductSettingData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/products/' + product.id + '/settings', data)
            .then((res: AxiosResponse<{ status: boolean, productSettings: CustomerProductSetting[] }>) => {
            setSubmitting(false);
            if(res.data.status) {
                setProductSettings(res.data.productSettings);
            }
            setOpen(false);
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <Grid container>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                <Card>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                    </Box>
                    <Divider/>
                    <CardContent sx={{p: 4}}>
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                Setting List
                            </Typography>
                            <Typography variant="subtitle2">
                                Manage Setting to your Product
                            </Typography>
                            <Box>
                                <Grid container spacing={2}>

                                    {settings.map((setting, index) => {
                                        return <Grid item xs={6} key={index}>
                                            <List
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    mb: 1,
                                                    border: '1px solid #ddd',
                                                    borderRadius: '5px'
                                                }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItem>
                                                    {settingChecked.indexOf(setting.ref) !== -1 &&
                                                        <ListItemButton onClick={() => handelSettingClick(setting)}>
                                                            <ListItemText id={'switch-list-label-' + setting.ref}
                                                                          primary={setting.name} secondary={setting.info}/>
                                                        </ListItemButton>
                                                    }
                                                    {settingChecked.indexOf(setting.ref) === -1 && <ListItemText id={'switch-list-label-' + setting.ref}
                                                                                                                 primary={setting.name} secondary={setting.info}/> }

                                                    {currentLoader !== setting.ref && <Switch
                                                        edge="end"
                                                        onChange={() => handleSettingToggle(setting)}
                                                        checked={settingChecked.indexOf(setting.ref) !== -1}
                                                        inputProps={{
                                                            'aria-labelledby': 'switch-list-label-' + setting.ref,
                                                        }}
                                                    />}
                                                    { currentLoader === setting.ref && <CircularProgress  color="inherit" />}
                                                </ListItem>
                                                <Collapse in={settingChecked.indexOf(setting.ref) !== -1} timeout="auto"
                                                          unmountOnExit>

                                                    <List sx={{borderTop: '1px solid #ddd'}} component="div"
                                                          disablePadding>
                                                        {setting.features.map((feature, featureIndex) => {
                                                            return <ListItem key={featureIndex}>
                                                                {featureChecked.indexOf(setting.ref + '-' + feature.ref) !== -1 &&
                                                                    <ListItemButton onClick={() => handelSettingClick(setting, feature)}>
                                                                        <ListItemText
                                                                            id={'check-list-label-' + setting.ref + '-' + feature.ref}
                                                                            primary={feature.name} secondary={feature.info}/>
                                                                    </ListItemButton>
                                                                }
                                                                {featureChecked.indexOf(setting.ref + '-' + feature.ref) === -1 && <ListItemText
                                                                    id={'check-list-label-' + setting.ref + '-' + feature.ref}
                                                                    primary={feature.name} secondary={feature.info}/> }

                                                                { currentLoader !== (setting.ref + '-' + feature.ref) &&
                                                                    <Checkbox
                                                                        edge="end"
                                                                        onChange={() => handleFeatureToggle(feature, setting)}
                                                                        checked={featureChecked.indexOf(setting.ref + '-' + feature.ref) !== -1}
                                                                        inputProps={{
                                                                            'aria-labelledby': 'check-list-label-' + setting.ref + '-' + feature.ref,
                                                                        }}
                                                                    />
                                                                }
                                                                { currentLoader === (setting.ref + '-' + feature.ref) && <CircularProgress size="1rem"  color="inherit" />}
                                                            </ListItem>
                                                        })}

                                                    </List>
                                                </Collapse>
                                            </List>
                                        </Grid>
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                        <Divider/>
                    </CardContent>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Setting Detail</DialogTitle>
                        <DialogContent>
                        { currentSetting && <CustomerProductSettingForm handleSubmit={handleLogin} setting={currentSetting}/>}
                        </DialogContent>
                    </Dialog>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CustomerProductSettingDetail;