import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {DataTableContainButton} from "../type";
import { NavLink as RouterLink } from 'react-router-dom';

function ContainButton({contain, buttonText, text, buttonHref, textHref}: DataTableContainButton) {

    if (contain) {
        return (
            <div>
                <IconButton
                    aria-label="Add"
                    size="small"
                    component={RouterLink}
                    to={textHref}>
                    {text}
                </IconButton>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton
                    aria-label="Add"
                    size="small"
                    component={RouterLink}
                    to={buttonHref}>
                    <AddIcon/> {buttonText}
                </IconButton>
            </div>
        );
    }


}

export default ContainButton;
