import {Card, Container, Grid} from '@mui/material';
import {DataTableAction} from "../../../../components/DataTable/type";
import {StateData} from "../../../../types/zone";
import ActionButton from "../../../../components/DataTable/ActionButton";
import DataTable from "../../../../components/DataTable";
import {useState} from "react";

function StateListDataTable() {
    const reFetchData = (type: string) => {
        const newParams = params.map((param) => {
            if (param.name === '__i') {
                param.value = (parseInt(param.value) + 1) + '';
            }
            return param;
        })
        setParams(newParams);
    }
    const actions: DataTableAction[] = [{type: 'edit', to: '/states/:id/edit'}, {type: 'delete', to: 'admin/states/:id'}];
    const columns = [
        {title: 'Id', field: 'id'},
        {title: 'Name', field: 'name'},
        {title: 'Country', field: 'country.full_name'},
        {
            title: 'Action',
            render: (rowData: StateData) => {
                return (<ActionButton reFetchData={reFetchData}  actions={actions} id={rowData.id} module={'State('+ rowData.name + ')'} />);
            }
        }
    ]
    const url = 'admin/states';
    const [params, setParams] = useState([{name: '__i', value: '1'}])

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                marginTop={2}
            >
                <Grid item xs={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                            cusParams={params}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default StateListDataTable;
