import { Helmet } from 'react-helmet-async';
import {useNavigate} from "react-router";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {post} from "../../../services/ApiService";
import {showValidationError} from "../../../services/FormService";
import {PageHeader} from "../../../components/PageHeader";
import BlogCreateForm from "./BlogCreateForm";
import { BlogInputData } from 'src/types/Blog';



function BlogCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/blogs',
            title: 'Blog List',
        }
    ]
    const handleLogin = (data: BlogInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('short_desc', data.short_desc)
        formData.append('description', data.description)
        formData.append('posted_by', data.posted_by)
        if (data.image) {
            formData.append('image', data.image)
          } else {
            formData.append('image', null)
          }
        post('admin/blog', formData).then(() => {
            setSubmitting(false);
            navigate('/blogs')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Blog Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Blog Create" showButton={false}/>
            <BlogCreateForm handleSubmit={handleLogin} />
        </>
    );
}

export default BlogCreate;
