import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@mui/material';
import {CustomerDatabaseMigrationLoadingProps,} from "./types";
import * as React from "react";
import CustomerDatabaseList from "./CustomerDatabaseList";

const CustomerDatabaseMigrationLoading = (customerDatabaseInfoProps: CustomerDatabaseMigrationLoadingProps) => {
    return (
        <Grid container spacing={2} pt={2}>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                {customerDatabaseInfoProps.databaseLoaderStatus && <Card>
                    <CardHeader title="Customer List"/>
                    <Divider/>
                    <CardContent>
                        <Grid container spacing={2}>
                            {customerDatabaseInfoProps.customerDatabases.map((customer, key) => {
                                 return <CustomerDatabaseList customer={customer} key={key} />;
                            })}
                        </Grid>
                    </CardContent>
                </Card>}
            </Grid>
        </Grid>
    );
}

export default CustomerDatabaseMigrationLoading;