import {Helmet} from 'react-helmet-async';
import CustomerProductDetail from "./CustomerProductDetail";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {get} from "../../../services/ApiService";
import {AxiosResponse} from "axios";
import {CustomerData, CustomerProduct as CustomerProductData, ProductService} from "../../../types/Customer";
import {PageHeader} from "../../../components/PageHeader";

const defaultProduct: CustomerProductData = {
    id: '',
    name: '',
    api_key: '',
    customer_id: '',
    country_id: '',
    country_name: '',
    services: [],
    features: [],
    settings: [],
}

export type ProductConfig = {
    currency: string,
    distance_unit: string,
    rate_per_minute_included: string,
    accessed_countries: string[],
}

function CustomerProduct() {
    const [dataFetched, setDataFetched] = useState(false);
    const [product, setProduct] = useState(defaultProduct);
    const [config, setConfig] = useState<ProductConfig>({currency: '', distance_unit: '', rate_per_minute_included: '', accessed_countries: []});
    const [services, setServices] = useState<ProductService[]>([]);
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]
    useEffect(() => {
        get('admin/customers/' + id + '/product').then((res: AxiosResponse<{ customer: CustomerData, services: ProductService[], config: ProductConfig }>) => {
            setProduct(res.data.customer.product);
            setServices(res.data.services);
            setConfig(res.data.config);
            setDataFetched(true);
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Customer Product</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Product" showButton={false}/>
            {dataFetched && <CustomerProductDetail product={product} services={services} config={config}/>}

        </>
    );
}

export default CustomerProduct;