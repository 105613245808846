import CountryList from "../content/zone/country/CountryList";
import CountryCreate from "../content/zone/country/CountryCreate";
import CountryEdit from "../content/zone/country/CountryEdit";
import StateList from "../content/zone/states/StateList";
import StateCreate from "../content/zone/states/StateCreate";
import StateEdit from "../content/zone/states/StateEdit";
import AreaList from "../content/zone/area/AreaList";
import AreaCreate from "../content/zone/area/AreaCreate";
import ZoneEdit from "../content/zone/area/AreaEdit";
import LocationList from "../content/zone/location/LocationList";
import LocationCreate from "../content/zone/location/LocationCreate";
import LocationEdit from "../content/zone/location/LocationEdit";

export const ZoneRoutes = [
    {
        path: 'countries',
        element: <CountryList />
    },
    {
        path: 'countries/create',
        element: <CountryCreate />
    },
    {
        path: 'countries/:id/edit',
        element: <CountryEdit />
    },
    {
        path: 'states',
        element: <StateList />
    },
    {
        path: 'states/create',
        element: <StateCreate />
    },
    {
        path: 'states/:id/edit',
        element: <StateEdit />
    },
    {
        path: 'zones',
        element: <AreaList />
    },
    {
        path: 'zones/create',
        element: <AreaCreate />
    },
    {
        path: 'zones/:id/edit',
        element: <ZoneEdit />
    },
    {
        path: 'locations',
        element: <LocationList />
    },
    {
        path: 'locations/create',
        element: <LocationCreate />
    },
    {
        path: 'locations/:id/edit',
        element: <LocationEdit />
    },
];