import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Grid} from '@mui/material';
import {CustomerProduct, ProductService, ProductServiceFeature} from "../../../types/Customer";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import {NavLink} from "react-router-dom";

export default function ProductSettingListDataTable({features}: { features: ProductServiceFeature[]}) {
    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid container spacing={1}>
                            {features.map((feature, index) => {
                                return <Grid key={index} item xs={6}>
                                    <Card>
                                        <Box  display="flex"
                                              alignItems="center"
                                              justifyContent="space-between">
                                            <CardContent>
                                                <Typography variant="h3">
                                                    {feature.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {feature.info}
                                                </Typography>
                                            </CardContent>
                                            <Button variant="text" startIcon={<SettingsIcon />} component={NavLink}
                                                    to={'/product-settings/' + feature?.reference + '/manage'}>
                                               Manage
                                            </Button>
                                        </Box>

                                    </Card>
                                </Grid>
                            })}

                        </Grid>

                    </Box>
            </Grid>
        </Grid>
    );
}