import { Helmet } from 'react-helmet-async';
import {PageHeader} from "../../../../components/PageHeader";
import LocationListDataTable from "./LocationListDataTable";

const LocationList = () => {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]
    const headerButton =
        {
            href: '/locations/create',
            title: 'Location Create',
        }
    return (
        <>
            <Helmet>
                <title>Location List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} showButton={true} title="Location List" headerButton={headerButton}/>
            <LocationListDataTable/>

        </>
    );
}

export default LocationList;
