import { Field, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  CircularProgress
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormSubmit } from '../../../types/Form';
import { BlogData, BlogInputData } from 'src/types/Blog';

import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';

type BlogCreateFormProp = {
  handleSubmit: FormSubmit;
  initialValues?: BlogData;
};

const CForm = (props: FormikProps<BlogInputData>) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = props;


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      setFieldValue("image", file);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={2} pt={2}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Card>
              <CardHeader
                title={props.initialValues.slug ? 'Blog Edit Form' : 'Blog Create Form'}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="blog_title"
                      fullWidth
                      label="Titile"
                      name="title"
                      value={values.title ? values.title : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title ? errors.title : ''}
                      error={touched.title && Boolean(errors.title)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="blog_posted_by"
                      fullWidth
                      label="Posted By"
                      name="posted_by"
                      value={values.posted_by ? values.posted_by : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.posted_by ? errors.posted_by : ''}
                      error={touched.posted_by && Boolean(errors.posted_by)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      type="file"
                      name="image"
                      onChange={handleFileChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="blog_short_desc"
                      fullWidth
                      label="Short Description"
                      name="short_desc"
                      value={values.short_desc ? values.short_desc : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.short_desc ? errors.short_desc : ''}
                      error={touched.short_desc && Boolean(errors.short_desc)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="blog_description"
                      fullWidth
                      label="Description"
                      name="description"
                      value={values.description ? values.description : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.description ? errors.description : ''}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && <CircularProgress size={20} />}
                      {!isSubmitting && (props.initialValues.slug ? 'Edit' : 'Create')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
const BlogCreateForm = (props: BlogCreateFormProp) => {
  const { handleSubmit, initialValues } = props;
  const FormElement = withFormik({
    mapPropsToValues: () => ({
      id: initialValues?.id ?? '',
      title: initialValues?.title ?? '',
      slug: initialValues?.slug ?? '',
      image_path: initialValues?.image_path ?? '',
      image: initialValues?.image ?? null,
      short_desc: initialValues?.short_desc ?? '',
      description: initialValues?.description ?? '',
      posted_by: initialValues?.posted_by ?? ''
    }),
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title Required'),
      short_desc: Yup.string().required('Short Description Required')
    }),
    handleSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      handleSubmit(values, setSubmitting, setErrors);
    }
  })(CForm);
  return <FormElement />;
};

export default BlogCreateForm;
