import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import BlogCreateForm from '../BlogCreate/BlogCreateForm';
import { BlogData, BlogInputData } from 'src/types/Blog';
import { showValidationError } from 'src/services/FormService';
import { get, post } from 'src/services/ApiService';
import { FormSetError, FormSubmitting } from 'src/types/Form';
import { PageHeader } from 'src/components/PageHeader';

const defaultInitialValues: BlogData = {
    id: '',
    title: '',
    slug: '',
    image_path: '',
    image: null,
    short_desc: '',
    description: '',
    posted_by: '',
    status: true
}
function BlogEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState()
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/blogs',
            title: 'Blog List',
        }
    ]

    useEffect(() => {
        get('admin/blog/' + id).then((res) => {
            setInitialValues(res.data.data);
        })
    }, [])

    const handleSubmit = (data: BlogData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        const formData = new FormData()
        formData.append('id', data.id)
        formData.append('title', data.title)
        formData.append('short_desc', data.short_desc)
        formData.append('description', data.description)
        formData.append('posted_by', data.posted_by)
        if (data.image) {
            formData.append('image', data.image)
          } else {
            formData.append('image', null)
          }
        post('admin/update/blog/' + id, formData).then(() => {
            setSubmitting(false);
            navigate('/blogs')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Blog Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Blog Edit" showButton={false}/>
            <BlogCreateForm handleSubmit={handleSubmit} initialValues={initialValues}/>
        </>
    );
}

export default BlogEdit;
