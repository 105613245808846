import {Helmet} from 'react-helmet-async';
import CustomerProductEditForm from "./CustomerProductEditForm";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {get, post} from "../../../services/ApiService";
import {AxiosResponse} from "axios";
import {CustomerData, CustomerProduct} from "../../../types/Customer";
import {PageHeader} from "../../../components/PageHeader";
import {Box, Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import {CustomerInputs} from "../CustomerCreate/types";
import {showValidationError} from "../../../services/FormService";
import {FormSetError, FormSubmitting} from "../../../types/Form";

const defaultProduct: CustomerProduct = {
    id: '',
    customer_id: '',
    country_id: '',
    name: '',
    api_key: '',
    services: [],
    features: [],
    settings: [],
}

function CustomerProductEdit() {
    const [product, setProduct] = useState(defaultProduct);
    const navigate = useNavigate();
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]
    useEffect(() => {
        get('admin/customers/' + id + '/product').then((res: AxiosResponse<{ customer: CustomerData }>) => {
            setProduct(res.data.customer.product);
        })
    }, [])

    const handleLogin = (data: CustomerInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/customers/' + id + '/product' , data).then((res) => {
            setSubmitting(false);
            navigate('/customers/' + id + '/product')
        }).catch((error) => {
            showValidationError(error.response, setErrors, [{from: 'mobile', to: 'mobile_'}]);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Customer Product From</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Product Edit" showButton={false}/>
            <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Card>
                        <Box
                            p={3}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography variant="h4" gutterBottom>
                                    Product Detail Form
                                </Typography>
                                <Typography variant="subtitle2">
                                    Manage details related to your Product
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <CardContent sx={{ p: 4 }}>
                            <CustomerProductEditForm product={product} handleSubmit={handleLogin}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default CustomerProductEdit;