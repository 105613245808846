import { Helmet } from 'react-helmet-async';
import {useNavigate} from "react-router";
import {FormSetError, FormSubmitting} from "../../../../types/Form";
import {CountryInputData, LocationInputData} from "../../../../types/zone";
import {post} from "../../../../services/ApiService";
import {showValidationError} from "../../../../services/FormService";
import {PageHeader} from "../../../../components/PageHeader";
import LocationCreateForm from "./LocationCreateForm";


function LocationCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/locations',
            title: 'Location List',
        }
    ]
    const handleLogin = (data: LocationInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/locations', data).then(() => {
            setSubmitting(false);
            navigate('/locations')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Location Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Location Create" showButton={false}/>
            <LocationCreateForm handleSubmit={handleLogin} />
        </>
    );
}

export default LocationCreate;
