import Crypto from "../content/dashboards/Crypto";
import ProductSettingList from "../content/setting/ProductSettingList";
import ProductSettingCreate from "../content/setting/ProductSettingCreate";
import ProductServiceList from "../content/services/ProductServiceList";
import ProductServiceCreate from "../content/services/ProductServiceCreate";
import ProductServiceEdit from "../content/services/ProductServiceEdit";
import BlogList from "../content/blogs/BlogList";
import BlogCreate from "../content/blogs/BlogCreate";
import BlogEdit from "../content/blogs/BlogEdit";
import UpComingUpdatesList from "src/content/upcomingUpdates/UpComingUpdatesList";
import UpcomingUpdatesCreate from "src/content/upcomingUpdates/UpComingUpdatesCreate";
import UpComingUpdatesEdit from "src/content/upcomingUpdates/UpComingUpdatesEdit";

export const CommonRoutes = [
    {
        path: 'dashboard',
        element: <Crypto />
    },
    {
        path: 'product-settings',
        element: <ProductSettingList />
    },
    {
        path: 'product-settings/:id/manage',
        element: <ProductSettingCreate />
    },
    {
        path: 'product-services',
        element: <ProductServiceList />
    },
    {
        path: 'product-settings/create',
        element: <ProductSettingCreate />
    },
    {
        path: 'product-services/create',
        element: <ProductServiceCreate />
    },
    {
        path: 'product-services/:id/edit',
        element: <ProductServiceEdit />
    },
    {
        path: 'blogs',
        element: <BlogList />
    },
    {
        path: 'blog/create',
        element: <BlogCreate />
    },
    {
        path: 'blog/:id/edit',
        element: <BlogEdit />
    },
    {
        path: 'upcoming-updates',
        element: <UpComingUpdatesList />
    },
    {
        path: 'upcoming-updates/create',
        element: <UpcomingUpdatesCreate />
    },
    {
        path: 'upcoming-update/:id/edit',
        element: <UpComingUpdatesEdit />
    },
];