import { Card, Container, Grid, Switch} from '@mui/material';
import DataTable from "../../../components/DataTable";
import ActionButton from "../../../components/DataTable/ActionButton";
import {DataTableAction} from "../../../components/DataTable/type";
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { UpcomingUpdatesData } from 'src/types/UpcomingUpdates';

function UpcomingUpdatesDataTable() {
    const actions: DataTableAction[] = [{type: 'edit', to: '/upcoming-update/:id/edit'},{type: 'delete',  to: 'admin/delete/upcoming-update/:id'}];
    const reFetchData = (type: string) => {
        const newParams = params.map((param) => {
            if (param.name === '__i') {
                param.value = (parseInt(param.value) + 1) + '';
            }
            return param;
        })
        setParams(newParams);
    }
    const columns = [
        {title: 'Id', field: 'id', width: '10%'},
        {title: 'Title', field: 'title'},
        {title: 'Description', field: 'description'},
        {title: 'Tag', field: 'tag'},
        {
            title: 'Action',
            render: (rowData: UpcomingUpdatesData) => {
                return (<ActionButton reFetchData={reFetchData}  actions={actions} id={rowData.id}/>);
            }
        },
    ]
    const url = 'admin/upcoming-updates';
    const [params, setParams] = useState([{name: '__i', value: '1'}])
    const navigate = useNavigate();

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default UpcomingUpdatesDataTable;
