import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItemButton,
  Radio,
  RadioGroup
} from '@mui/material';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { GoogleMapPolygonProps, SearchPolygonRes } from '../../types/zone';
import {
  formatPolygon,
  getMapBoundBox,
  plotPolygon,
  populatePoly,
  setBoundToMap
} from '../../services/GoogleMapService';
import { any } from 'prop-types';
import html2canvas from 'html2canvas';

const GoogleMapPolygon = (googleMapPolygonProps: GoogleMapPolygonProps) => {
  const { place, position, onPolySelected, polygon } = googleMapPolygonProps;
  const mapNUll: google.maps.Map | null = null;
  const polygonUrl =
    'https://nominatim.openstreetmap.org/search.php?q=_placeholder_&polygon_geojson=1&format=json';
  const defaultProps = {
    center: {
      lat: 30,
      lng: 30
    },
    zoom: 0
  };
  const defaultFoundPolygons: SearchPolygonRes[] = [];
  const [foundPolygons, setFoundPolygons] = useState(defaultFoundPolygons);
  const [map, setMap] = useState(mapNUll);
  const [selectedId, setSelectedId] = useState('');
  const [polygons, setPolygons] = useState([]);
  const [shownFoundPolygonLoader, setShownFoundPolygonLoader] = useState(false);

  const [drawMode, setDrawMode] = useState('auto');
  const drawingManagerRef = useRef<any>(null);

  const [base64Image, setBase64Image] = useState('');
  const divRef = useRef(null);
  const captureAndConvert = () => {
    const element = divRef.current;
    html2canvas(element,{useCORS: true}).then((canvas) => {
      const base64 = canvas.toDataURL('image/png');
      setBase64Image(base64);
    });
  };
  const handelPolygonClick = (poly: SearchPolygonRes) => {
    if (map) {
      setSelectedId(poly.place_id);
      polygons.forEach((poly: google.maps.Polygon) => {
        poly.setMap(null);
      });
      const plottedPolygons = plotPolygon(poly, formatPolygon(poly), map);
      setPolygons(plottedPolygons);
      setBoundToMap(poly, map);
     
      onPolySelected(populatePoly(plottedPolygons), getMapBoundBox(map), base64Image);
    }
  };
  const handleApiLoaded = (googleMap: google.maps.Map) => {
    setMap(googleMap);
  };
  useEffect(() => {
    if (position.zoom !== 0) {
      if (map) {
        map.setCenter({ lat: position.lat, lng: position.lng });
        map.setZoom(position.zoom);
      }
    }
  }, [position]);

  useEffect(() => {
    if (place.lat !== 0) {
      if (map) {
        map.setCenter({ lat: place.lat, lng: place.lng });
        map.setZoom(position.zoom);
      }
    }
  }, [place]);

  useEffect(() => {
    if (polygon?.geojson?.coordinates.length > 0) {
      handelPolygonClick(polygon);
      setFoundPolygons([polygon]);
    }
  }, [polygon, map]);
  
  useEffect(() => {
    captureAndConvert();
  }, [polygons]);

  useEffect(() => {
    if (place.name !== '') {
      setShownFoundPolygonLoader(true);
      const url = polygonUrl.replace('_placeholder_', place.name);
      axios.get(url).then((res: AxiosResponse<SearchPolygonRes[]>) => {
        const results = res.data.filter((ele) => {
          return (
            ele.osm_type === 'relation' ||
            (ele.osm_type === 'way' &&
              (ele.geojson?.type === 'Polygon' ||
                ele.geojson?.type === 'MultiPolygon'))
          );
        });
        setFoundPolygons(results);
        setShownFoundPolygonLoader(false);
      });
    }
  }, [place]);

  useEffect(() => {
    if (map) {
      if (drawMode === 'draw') {
        initDraw(map, window.google.maps);
      } else {
        if (drawingManagerRef.current) {
          drawingManagerRef.current.setMap(null);
        }
        polygons.forEach((poly: google.maps.Polygon) => {
          poly.setMap(null);
        });
        setPolygons([]);
      }
    }
  }, [drawMode, map]);

  const initDraw = (map: any, maps: any) => {
    const manager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON]
      }
    });

    manager.setMap(map);
    drawingManagerRef.current = manager;

    // Add event listener for polygon complete
    maps.event.addListener(
      manager,
      'overlaycomplete',
      (event: google.maps.drawing.OverlayCompleteEvent) => {
        handlePolygonComplete(event);
      }
    );
  };

  const handlePolygonComplete = (
    event: google.maps.drawing.OverlayCompleteEvent
  ) => {
    if (event.type === 'polygon') {
      const polygon = event.overlay as google.maps.Polygon;
      captureAndConvert();
      onPolySelected(populatePoly([polygon]), getMapBoundBox(map), base64Image);
    }
  };

  const handleDrawModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDrawMode(event.target.value);

    // Clear existing polygons when drawing mode is disabled
    // if (!event.target.checked && map) {
    //     polygons.forEach((poly: google.maps.Polygon) => {
    //         poly.setMap(null);
    //     });
    //     setPolygons([]);
    // }
  };

  const CustomMarker = () => (
    <div>
      <img
        src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"
        alt="Marker"
        style={{
          width: '30px',
          height: '30px'
        }}
      />
    </div>
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Draw Mode</FormLabel>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControlLabel
              value="auto"
              control={<Radio />}
              label="Auto"
              checked={drawMode === 'auto'}
              onChange={handleDrawModeChange}
            />
            <FormControlLabel
              value="draw"
              control={<Radio />}
              label="Draw"
              checked={drawMode === 'draw'}
              onChange={handleDrawModeChange}
            />
            {/* <FormControlLabel
                            value="radius"
                            control={<Radio />}
                            label="Radius"
                            checked={drawMode === 'radius'}
                            onChange={handleDrawModeChange}
                        /> */}
          </Grid>
        </FormControl>
      </Grid>
      <Grid ref={divRef} item xs={9} style={{ height: '450px' }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals={true}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => {
            handleApiLoaded(map);
          }}
        >
          {place.lat && <CustomMarker />}
        </GoogleMapReact>
      </Grid>
      <Grid item xs={3}>
        <h4 style={{ textAlign: 'center' }}>Found Polygons</h4>
        {shownFoundPolygonLoader && (
          <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
            <LinearProgress color="inherit" />
          </Stack>
        )}
        {!shownFoundPolygonLoader &&
          foundPolygons.map((poly, index) => {
            return (
              <List key={index}>
                <ListItemButton
                  selected={selectedId === poly.place_id}
                  onClick={() => handelPolygonClick(poly)}
                >
                  {poly.display_name}
                </ListItemButton>
              </List>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default GoogleMapPolygon;