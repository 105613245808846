import axios, {AxiosResponse} from "axios";
import {GetAuthHeader} from "./AuthService";
const baseUrl = process.env.REACT_APP_API_URL;
export const authPost = (url: string, data: object):Promise<AxiosResponse> => {
   return axios
       .post(baseUrl + url, data)
}
export const get = (url: string, params = {}):Promise<AxiosResponse> => {
   let header = GetAuthHeader();
   if(!header) {
      header = { Authorization: 'no-token'};
   }
   return axios
       .get(baseUrl + url, {
          headers: header,
           params: params
       })
}
export const post = (url: string, data: object):Promise<AxiosResponse> => {
   let header = GetAuthHeader();
   if(!header) {
      header = { Authorization: 'no-token'};
   }
   return axios
       .post(baseUrl + url, data, {
          headers: header
       })
}
export const del = (url: string):Promise<AxiosResponse> => {
   let header = GetAuthHeader();
   if(!header) {
      header = { Authorization: 'no-token'};
   }
   return axios
       .post(baseUrl + url,{_method: 'DELETE'} ,{
          headers: header
       })
}