import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {FormSetError, FormSubmitting} from "../../../../types/Form";
import {LocationData, LocationInputData} from "../../../../types/zone";
import {get, post} from "../../../../services/ApiService";
import {PageHeader} from "../../../../components/PageHeader";
import {useEffect, useState} from "react";
import {showValidationError} from "../../../../services/FormService";
import LocationCreateForm from "../LocationCreate/LocationCreateForm";

const defaultInitialValues: LocationData = {
    id: '',
    name: '',
    type: 'airport',
    code: '',
    boundingbox: undefined,
    country_id: '',
    state_id: '',
    polygon_data: undefined
}
function LocationEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/locations',
            title: 'Location List',
        }
    ]

    useEffect(() => {
        get('admin/locations/' + id).then((res) => {
            setInitialValues(res.data);
        })
    }, [])

    const handleSubmit = (data: LocationInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        delete data.polygon_data;

        post('admin/locations/' + id, data).then(() => {
            setSubmitting(false);
            navigate('/locations')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Location Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Location Edit" showButton={false}/>
            <LocationCreateForm handleSubmit={handleSubmit} initialValues={initialValues}/>
        </>
    );
}

export default LocationEdit;
