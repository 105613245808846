import {Typography, Grid} from '@mui/material';

function PageHeader() {
    return (
        <Grid container alignItems="center">
            <Grid item sx={{minHeight:'62vh'}}>
                <Typography variant="h3" component="h3" gutterBottom>
                    Super Admin Dashboard
                </Typography>
                <Typography variant="subtitle2">
                    Today is a good day to start anything.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PageHeader;
