import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress
} from "@mui/material";
import IntlTelField from "../../../components/IntlTelField";
import TextField from "@mui/material/TextField";
import {handelCountrySelect, handelPhoneNumberChange} from "../../../components/IntlTelField/IntlTelField";
import {FormSubmit} from "../../../types/Form";
import {CustomerInputData} from "../../../types/Customer";
type CustomerFormProps = {
    handleSubmit: FormSubmit
}
const CForm = (props: FormikProps<CustomerInputData>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    return (
        <div>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title="Customer Create Form" />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="name"
                                            label="Name"
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.name ? errors.name : ""}
                                            error={touched.name && Boolean(errors.name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="email"
                                            label="Email"
                                            value={values.email || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.email ? errors.email : ""}
                                            error={touched.email && Boolean(errors.email)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <IntlTelField
                                            fieldId="mobile_"
                                            fieldName="mobile_"
                                            label='Phone No'
                                            size="small"
                                            variant="outlined"
                                            value={values.mobile_}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.mobile_ ? errors.mobile_ : ""}
                                            error={touched.mobile_ && Boolean(errors.mobile_)}
                                            onPhoneNumberChange={(... args) => {handelPhoneNumberChange(... args, 'mobile',setFieldValue )}}
                                            onSelectFlag={(... args) => {handelCountrySelect(...args, 'mobile', setFieldValue)}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                            <TextField
                                                id="password"
                                                label="Password"
                                                value={values.password || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.password ? errors.password : ""}
                                                error={touched.password && Boolean(errors.password)}
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                            />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting  && <CircularProgress size={20} />}
                                            {!isSubmitting  && 'Create'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const CustomerForm = (props: CustomerFormProps) => {
    const { handleSubmit } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({ mobile_: '', password: '', name: '', email: '' }),
        validationSchema: Yup.object().shape({
            mobile_: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
            name: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default CustomerForm;