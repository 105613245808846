import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {FormSubmit} from "../../../../types/Form";
import {CountryInputData} from "../../../../types/zone";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import currencyData from '../currencies.json';
interface Currency {
    symbol: string;
}
type Currencies = {
    [key: string]: Currency;
};
const currencyOptions = Object.keys(currencyData as Currencies).map((code) => (
    <MenuItem key={code} value={code}>
        {code}: {(currencyData as Currencies)[code].symbol}
    </MenuItem>
));
type CountryCreateFormProp = {
    handleSubmit: FormSubmit,
    initialValues ?: CountryInputData
}


const CForm = (props: FormikProps<CountryInputData>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props;


    return (
        <div>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title={'Country '+ (values.is_edit ? 'Edit' : 'Create') +' Form'} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="name"
                                            label="Code Name"
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.name ? errors.name : ""}
                                            error={touched.name && Boolean(errors.name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="full_name"
                                            label="Full Name"
                                            value={values.full_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.full_name ? errors.full_name : ""}
                                            error={touched.full_name && Boolean(errors.full_name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="google_map_name"
                                            label="Google Map Name"
                                            value={values.google_map_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.google_map_name ? errors.google_map_name : ""}
                                            error={touched.google_map_name && Boolean(errors.google_map_name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="google_map_short_name"
                                            label="Google Map Short Name"
                                            value={values.google_map_short_name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.google_map_short_name ? errors.google_map_short_name : ""}
                                            error={touched.google_map_short_name && Boolean(errors.google_map_short_name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="lat"
                                            label="Latitude"
                                            value={values.lat || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.lat ? errors.lat : ""}
                                            error={touched.lat && Boolean(errors.lat)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="lng"
                                            label="Longitude"
                                            value={values.lng || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.lng ? errors.lng : ""}
                                            error={touched.lng && Boolean(errors.lng)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="zoom_level"
                                            label="Zoom Level"
                                            value={values.zoom_level || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.zoom_level ? errors.zoom_level : ""}
                                            error={touched.zoom_level && Boolean(errors.zoom_level)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="distance_unit"
                                            select
                                            fullWidth
                                            label="Distance Unit"
                                            name="distance_unit"
                                            value={ values.distance_unit || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.distance_unit ? errors.distance_unit : ""}
                                            error={touched.distance_unit && Boolean(errors.distance_unit)}
                                        >

                                            <MenuItem value="mile"> Mile </MenuItem>
                                            <MenuItem value="km"> Kilometer </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="currency"
                                            select
                                            fullWidth
                                            label="Currency Unit"
                                            name="currency"
                                            value={ values.currency || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.currency ? errors.currency : ""}
                                            error={touched.currency && Boolean(errors.currency)}
                                        >

                                            {/* <MenuItem value="dollar_us">US Dollar ($) </MenuItem>
                                            <MenuItem value="dollar_aus">AUS Dollar ($) </MenuItem>
                                            <MenuItem value="pound"> Pound (£) </MenuItem>
                                            <MenuItem value="euro"> Euro (€) </MenuItem> */}
                                              {currencyOptions}
                                        </TextField>
                                    </Grid>


                                    <Grid item xs={12} >
                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting  && <CircularProgress size={20} />}
                                            {!isSubmitting  && ( values.is_edit ? 'Update' : 'Create')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const CountryCreateForm = (props: CountryCreateFormProp) => {
    const { handleSubmit, initialValues } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({
            name: initialValues?.name ?? '',
            full_name: initialValues?.full_name ?? '',
            google_map_name: initialValues?.google_map_name ?? '',
            google_map_short_name: initialValues?.google_map_short_name ?? '' ,
            distance_unit: initialValues?.distance_unit ?? 'mile' ,
            currency: initialValues?.currency ?? 'dollar_us' ,
            lat: initialValues?.lat ?? undefined,
            lng: initialValues?.lng ?? undefined,
            zoom_level: initialValues?.zoom_level ?? undefined,
            is_edit: Boolean(initialValues?.zoom_level > 0)
        }),
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            full_name: Yup.string().required("Required"),
            google_map_name: Yup.string().required("Required"),
            google_map_short_name: Yup.string().required("Required"),
            lat: Yup.number().required("Required"),
            lng: Yup.number().required("Required"),
            zoom_level: Yup.number().required("Required"),
            currency: Yup.string().required("Required"),
            distance_unit: Yup.string().required("Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default CountryCreateForm;