import {Grid, Button, Breadcrumbs, Typography, Link} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

type BreadCrumbsProp = {
    href: string;
    title: string;
}

export  type HeaderButton = {
    href: string;
    title: string;
}

type PageHeaderProps = {
    breadcrumbs: BreadCrumbsProp[],
    title: string;
    showButton: boolean;
    headerButton ?: HeaderButton;
    extraButton ?: HeaderButton;
}

export const  PageHeader = (pageHeaderProps: PageHeaderProps) => {

    return (
        <Grid container direction="row"
              justifyContent="space-between"
              alignItems="center" pl={2} pt={1} pb={1}>
            <Grid item >
                <Breadcrumbs aria-label="breadcrumb">
                    {pageHeaderProps.breadcrumbs.map((breadcrumb, index) => {
                        return (<Link component={RouterLink} underline="hover" color="inherit" to={breadcrumb.href} key={index}>
                            {breadcrumb.title}
                        </Link>)
                    })}
                    <Typography color="text.primary">{pageHeaderProps.title}</Typography>
                </Breadcrumbs>
            </Grid>

            <Grid item>
                {pageHeaderProps.extraButton && <Button size='small' component={RouterLink} to={pageHeaderProps.extraButton.href}>
                    {pageHeaderProps.extraButton.title}
                </Button>}
                {pageHeaderProps.showButton && <Button size='small' component={RouterLink} to={pageHeaderProps.headerButton.href}>
                    {pageHeaderProps.headerButton.title}
                </Button>}

            </Grid>
        </Grid>
    );
}
