import {AuthHeader, LocalstorageUserData} from "../types/Auth";

export const GetAuthHeader = (): AuthHeader | null => {
    const userString: string | null = localStorage.getItem('user')
    const user: LocalstorageUserData | null = userString ? JSON.parse(userString) : null;
    if (user && user.token) {
        return {Authorization: 'Bearer ' + user.token};
    } else {
        return null;
    }
}

export const isLoggedIn = (): boolean => {
    const userString: string | null = localStorage.getItem('user')
    return userString !== null;
}

export const setLoggedInData = (data: string): void => {
    localStorage.setItem('user', data)
}

export const logout = (): void => {
    localStorage.removeItem('user')
}