import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import { LinearProgress} from "@mui/material";

type DialogAlertProps = {
  status: boolean;
  loading: boolean;
  title: string;
  info: string;
  handleOK: () => void,
  handleCancel: () => void
}

const DialogAlert = (dialogAlertProps: DialogAlertProps) => {

  return (
      <Dialog
          open={dialogAlertProps.status}
          onClose={dialogAlertProps.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogAlertProps.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogAlertProps.info}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={dialogAlertProps.loading} onClick={dialogAlertProps.handleCancel}>Disagree</Button>
          <Button disabled={dialogAlertProps.loading} onClick={dialogAlertProps.handleOK} autoFocus>
            Agree
          </Button>
        </DialogActions>
        {dialogAlertProps.loading && <LinearProgress />}
      </Dialog>
  );
}

export default DialogAlert;
