import { Helmet } from 'react-helmet-async';
import {PageHeader} from "../../../components/PageHeader";
import CustomerForm from "../../customers/CustomerForm";
import ProductSettingForm from "../ProductSettingForm";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {ProductSettingInputs} from "./types";
import {get, post} from "../../../services/ApiService";
import {useNavigate, useParams} from "react-router";
import {showValidationError} from "../../../services/FormService";
import {useEffect, useState} from "react";

const defaultInitialValues = {
    name: '',
    info: '',
    ref: '',
    icon: '',
    sort: 1,
    features: [{
        name: '',
        info: '',
        ref: '',
        icon: '',
        sort: 1,
    }]
}

function ProductSettingCreate() {
    const navigate = useNavigate();
    let {id} = useParams();
    const [initialValues, setInitialValue] = useState(defaultInitialValues)
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/product-settings',
            title: 'Product Setting List',
        }
    ]

    useEffect(() => {
        get('admin/product-settings/' + id).then((res) => {
            setInitialValue(res.data);
        })
    }, [])
    const handleLogin = (data: ProductSettingInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/product-settings', data).then((res) => {
            setSubmitting(false);
            navigate('/product-settings')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Product Setting Manage</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Product Setting Manage" showButton={false}/>
            <ProductSettingForm handleSubmit={handleLogin} initialValues={initialValues} isEdit={true}/>
        </>
    );
}

export default ProductSettingCreate;
