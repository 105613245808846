import {Helmet} from 'react-helmet-async';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {get, post} from "../../../services/ApiService";
import {AxiosResponse} from "axios";
import {CustomerData, ProductConfigInput,Country} from "../../../types/Customer";
import {PageHeader} from "../../../components/PageHeader";
import {ProductConfig} from "../CutomerProduct";
import CustomerProductConfigForm from "./CustomerProductConfigForm";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {showValidationError} from "../../../services/FormService";


function CustomerProductConfigEdit() {

    const [countries, setCountries] = useState<Country[]>(null)
    const [initialValue, setInitialValue] = useState<ProductConfigInput>(null)
    const [customerId, setCustomerId] = useState<string>('')
    const navigate = useNavigate();
    const [breadcrumbs, setServices] = useState([
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]);
    let {id} = useParams();
    useEffect(() => {
        get('admin/products/' + id + '/product-config')
            .then((res: AxiosResponse<{ customer: CustomerData, config: ProductConfig, countries: Country[] }>) => {
                setInitialValue(res.data.config);
                setCustomerId(res.data.customer.id);
                setCountries(res.data.countries);
                setServices([...breadcrumbs, {
                    href: '/customers/' + res.data.customer.id + '/product',
                    title: 'Customer Product',
                }]);
            })
    }, [])


    const handleLogin = (data: ProductConfigInput, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/products/' + id + '/product-config', data).then(() => {
            setSubmitting(false);
            navigate('/customers/' + customerId + '/product')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }
    return (
        <>
            <Helmet>
                <title>Customer Product Config Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Product Config Edit" showButton={false}/>
            {initialValue && <CustomerProductConfigForm handleSubmit={handleLogin} initialValues={initialValue} /> }
        </>
    );
}

export default CustomerProductConfigEdit;