import Noty from "noty";


export const notify = (message: string, error: boolean = false): void => {
    new Noty({
        type: error ? 'error' : 'success',
        layout: 'topRight',
        theme: 'metroui',
        timeout: 4000,
        text: message,
    }).show();
}