import {Card, Container, Grid} from '@mui/material';
import {DataTableAction} from "../../../../components/DataTable/type";
import {CountryData} from "../../../../types/zone";
import ActionButton from "../../../../components/DataTable/ActionButton";
import DataTable from "../../../../components/DataTable";

function CountryListDataTable() {
    const actions: DataTableAction[] = [{type: 'edit', to: '/countries/:id/edit'}];
    const columns = [
        {title: 'Id', field: 'id'},
        {title: 'Code', field: 'name'},
        {title: 'Name', field: 'full_name'},
        {title: 'Google Map Name', field: 'google_map_name'},
        {
            title: 'Action',
            render: (rowData: CountryData) => {
                return (<ActionButton actions={actions} id={rowData.id} />);
            }
        }
    ]
    const url = 'admin/countries';

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                marginTop={2}
            >
                <Grid item xs={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default CountryListDataTable;
