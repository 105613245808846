import {LatLng, SearchPolygonRes} from "../types/zone";
import PolygonOptions = google.maps.PolygonOptions;
import Polygon = google.maps.Polygon;

const Wkt = require('wicket/wicket')
export const formatPolygon = (rawPoly: SearchPolygonRes) => {
    const wktObj = new Wkt.Wkt();

    return wktObj.read(JSON.stringify(rawPoly.geojson));
}
export const plotPolygon = (object: SearchPolygonRes, polygonWkt: any, map: google.maps.Map) => {
    const googlePolygons = [];
    const polygonOptions: PolygonOptions = {
        fillColor: '#3b3862',
        strokeColor: '#FF0000',
        strokeWeight: 1,
        strokeOpacity: 0.8,
        fillOpacity: 0.55,
        editable: false
    }

    if (object.geojson.type === 'Polygon') {
        const triangleCoords: LatLng[] = [];
        object.geojson.coordinates[0].forEach(function (value5) {
            triangleCoords.push({lng: value5[0], lat: value5[1]});
        });

        const polygon = new google.maps.Polygon({...polygonOptions, paths: triangleCoords});
        googlePolygons.push(polygon);
        polygon.setMap(map);
    } else {
        const polyArray = polygonWkt.toObject(polygonOptions);
        polyArray.forEach(function (value22: any, key: number) {
            googlePolygons.push(value22);
            value22.setMap(map);
        });
    }
    return googlePolygons;
}


export const setBoundToMap = (poly: SearchPolygonRes, map: google.maps.Map) => {
    const southWest = new google.maps.LatLng(poly.boundingbox[0], poly.boundingbox[2]);
    const northEast = new google.maps.LatLng(poly.boundingbox[1], poly.boundingbox[3]);
    map.fitBounds(new google.maps.LatLngBounds(southWest, northEast));
}

export const populatePoly = (polygons: Polygon[]) => {

    const wkt = new Wkt.Wkt();
    if (polygons.length > 0) {
        polygons.forEach(function (value, key) {
            console.log(value,'value');
            if (key === 0) {
                wkt.fromObject(value);
            } else {
                const wkt2 = new Wkt.Wkt();
                wkt.merge(wkt2.fromObject(value));
            }
        });
    }
    return wkt.write();
}

export const getMapBoundBox = (map: google.maps.Map) => {
    map.setZoom(map.getZoom() + 1);
    const bound = map.getBounds();
    map.setZoom(map.getZoom() - 1);
    const ne = bound.getNorthEast();
    const sw = bound.getSouthWest();
    return '["' + sw.lat() + '","' + ne.lat() + '","' + sw.lng() + '","' + ne.lng() + '"]';
}