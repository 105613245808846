import {FormikProps, withFormik} from "formik";
import * as Yup  from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress
} from "@mui/material";
import IntlTelField from "../../../components/IntlTelField";
import TextField from "@mui/material/TextField";
import {handelCountrySelect, handelPhoneNumberChange} from "../../../components/IntlTelField/IntlTelField";
import {CustomerData, CustomerInputData} from "../../../types/Customer";
import {FormSubmit} from "../../../types/Form";

const CForm = (props: FormikProps<CustomerInputData & {mobile_full: string}>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    return (
        <div>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title="Customer Edit Form" />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="name"
                                            label="Name"
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.name ? errors.name : ""}
                                            error={touched.name && Boolean(errors.name)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="email"
                                            label="Email"
                                            value={values.email || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.email ? errors.email : ""}
                                            error={touched.email && Boolean(errors.email)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="domain"
                                            label="Domain"
                                            value={values.domain || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.domain ? errors.domain : ""}
                                            error={touched.domain && Boolean(errors.domain)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <IntlTelField
                                            fieldId="mobile_"
                                            fieldName="mobile_"
                                            label='Phone No'
                                            size="small"
                                            variant="outlined"
                                            defaultValue={values.mobile_full}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.mobile_ ? errors.mobile_ : ""}
                                            error={touched.mobile_ && Boolean(errors.mobile_)}
                                            onPhoneNumberChange={(... args) => {handelPhoneNumberChange(... args, 'mobile',setFieldValue )}}
                                            onSelectFlag={(... args) => {handelCountrySelect(...args, 'mobile', setFieldValue)}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="android_app_link"
                                            label="Android App Link"
                                            value={values.android_app_link || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.android_app_link ? errors.android_app_link : ""}
                                            error={touched.android_app_link && Boolean(errors.android_app_link)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="ios_app_link"
                                            label="IOS App Link"
                                            value={values.ios_app_link || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.ios_app_link ? errors.ios_app_link : ""}
                                            error={touched.ios_app_link && Boolean(errors.ios_app_link)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting  && <CircularProgress size={20} />}
                                            {!isSubmitting  && 'Update'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
type CustomerEditFormProp = {
    handleSubmit: FormSubmit;
    initialValues: CustomerInputData
}

const CustomerEditForm = (props: CustomerEditFormProp) => {
    const { handleSubmit, initialValues } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({
            mobile_: initialValues?.mobile ?? '',
            mobile: initialValues?.mobile ?? '',
            mobile_full: initialValues?.mobile ?? '',
            name: initialValues?.name ?? '',
            email: initialValues?.email ?? '',
            domain: initialValues?.domain ?? '',
            android_app_link: initialValues?.android_app_link ?? '',
            ios_app_link: initialValues?.ios_app_link ?? '',
        }),
        validationSchema: Yup.object().shape({
            mobile_: Yup.string().required("Required"),
            name: Yup.string().required("Required"),
            email: Yup.string().email().required("Required"),
        }),
        handleSubmit:(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement/>
}

export default CustomerEditForm;