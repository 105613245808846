import * as React from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import {
    Autocomplete,
    Avatar,
    ClickAwayListener, Divider,
    Grid,
    ListItem,
    ListItemAvatar, ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {ChangeEvent, SyntheticEvent} from "react";
interface Props {
    onSelect: (placeData: {name: string, lat: number, lng: number}) => void;
    helperText: string;
    label: string;
    error: boolean;
    stateNameValue ?:string
}

const GoogleAutoComplete: React.FC<Props> = ({ onSelect, helperText, error, label , stateNameValue}) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        debounce: 300,
    });

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
       setValue(e.target.value);
    };

    const handleSelect = (event: SyntheticEvent, selectedValue: any) => {
                    const text = selectedValue?.description ?? '';
                    setValue(text, false);
                    clearSuggestions();
                    if(text !== '') {
                        getGeocode({ address: text })
                            .then(results => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                                onSelect({name: text, lat: lat, lng: lng})
                            })
                            .catch(error => {
                                onSelect({name: '', lat: 0, lng: 0})
                            });
                    } else {
                        onSelect({name: '', lat: 0, lng: 0})
                    }
    }

    return (
        <ClickAwayListener onClickAway={() => clearSuggestions()}>
            <Autocomplete
                getOptionLabel={option =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={x => x}
                options={data}
                autoComplete
                fullWidth
                value={stateNameValue}
                includeInputInList
                onChange={(event, value) => {handleSelect(event, value)}}
                filterSelectedOptions
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        fullWidth
                        helperText={helperText}
                        error={error}
                        onChange={handleInput}
                    />
                )}
                renderOption={(props, option) => <li {...props}>{option.description}</li>}
            />
        </ClickAwayListener>
    );
};

export default GoogleAutoComplete;