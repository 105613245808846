import { Helmet } from 'react-helmet-async';
import {useNavigate} from "react-router";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {post} from "../../../services/ApiService";
import {showValidationError} from "../../../services/FormService";
import {PageHeader} from "../../../components/PageHeader";
import { UpcomingUpdatesInputData } from 'src/types/UpcomingUpdates';
import UpcomingUpdatesCreateForm from './UpcomingUpdatesCreateForm';



function UpcomingUpdatesCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/upcoming-updates',
            title: 'Blog List',
        }
    ]
    const handleLogin = (data: UpcomingUpdatesInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('description', data.description)
        formData.append('tag', data.tag)
        post('admin/upcoming-update', formData).then(() => {
            setSubmitting(false);
            navigate('/upcoming-updates')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Upcoming Updates Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Upcoming Updates Create" showButton={false}/>
            <UpcomingUpdatesCreateForm handleSubmit={handleLogin} />
        </>
    );
}

export default UpcomingUpdatesCreate;
