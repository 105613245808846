import { Helmet } from 'react-helmet-async';
import {PageHeader} from "../../../components/PageHeader";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import {ProductServiceInputs} from "./types";
import {post} from "../../../services/ApiService";
import {useNavigate} from "react-router";
import {showValidationError} from "../../../services/FormService";
import ProductServiceForm from "../ProductServiceForm";


function ProductServiceCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/product-services',
            title: 'Product Service List',
        }
    ]
    const handleLogin = (data: ProductServiceInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/product-services', data).then((res) => {
            setSubmitting(false);
            navigate('/product-services')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Product Service Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Product Service Create" showButton={false}/>
            <ProductServiceForm handleSubmit={handleLogin} />
        </>
    );
}

export default ProductServiceCreate;
