import { Helmet } from 'react-helmet-async';
import {useNavigate} from "react-router";
import {FormSetError, FormSubmitting} from "../../../../types/Form";
import {CountryInputData} from "../../../../types/zone";
import {post} from "../../../../services/ApiService";
import {showValidationError} from "../../../../services/FormService";
import {PageHeader} from "../../../../components/PageHeader";
import StateCreateForm from "./StateCreateForm";


function StateCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/states',
            title: 'State List',
        }
    ]
    const handleLogin = (data: CountryInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/states', data).then(() => {
            setSubmitting(false);
            navigate('/states')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>State Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="State Create" showButton={false}/>
            <StateCreateForm handleSubmit={handleLogin} />
        </>
    );
}

export default StateCreate;
