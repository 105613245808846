import { Helmet } from 'react-helmet-async';
import CustomerForm from "../CustomerForm";
import {useNavigate} from "react-router";
import {post} from "../../../services/ApiService";
import {CustomerInputs} from "./types";
import {showValidationError} from "../../../services/FormService";
import {PageHeader} from "../../../components/PageHeader";
import {FormSetError, FormSubmitting} from "../../../types/Form";


function CustomerCreate() {
    const navigate = useNavigate();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]
    const handleLogin = (data: CustomerInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/customers', data).then((res) => {
            setSubmitting(false);
            navigate('/customers')
        }).catch((error) => {
            showValidationError(error.response, setErrors, [{from: 'mobile', to: 'mobile_'}]);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Customer Create</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Customer Create" showButton={false}/>
            <CustomerForm handleSubmit={handleLogin} />
        </>
    );
}

export default CustomerCreate;
