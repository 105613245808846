import { Helmet } from 'react-helmet-async';
import CustomerListDataTable from "./CustomerListDataTable";
import {HeaderButton, PageHeader} from "../../../components/PageHeader";
import {useEffect, useState} from "react";
import {get} from "../../../services/ApiService";


function CustomerList() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]
    const defaultExtraButton: HeaderButton = null;
    const [extraButton, setExtraButton] = useState(defaultExtraButton)
    useEffect(() => {
        get('admin/customers-database-status').then((res) => {
            if(res.data.status) {
                setExtraButton( {
                    href: '/customers/database-migration',
                    title: 'Database Migration',
                })
            }
        });

    }, [])
    const headerButton =
        {
            href: '/customers/create',
            title: 'Customer Create',
        }
    return (
        <>
            <Helmet>
                <title>Customer List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Customer List" showButton={true} headerButton={headerButton} extraButton={extraButton}/>
            <CustomerListDataTable />
        </>
    );
}

export default CustomerList;
