import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormSubmit } from '../../../types/Form';

import {
  UpcomingUpdatesData,
  UpcomingUpdatesInputData
} from 'src/types/UpcomingUpdates';

type UpcomingUpdatesCreateFormProp = {
  handleSubmit: FormSubmit;
  initialValues?: UpcomingUpdatesData;
};

const CForm = (props: FormikProps<UpcomingUpdatesInputData>) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={2} pt={2}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Card>
              <CardHeader
                title={
                  props.initialValues.title
                    ? 'Upcoming Updates Edit Form'
                    : 'Upcoming Updates Create Form'
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="updates_title"
                      fullWidth
                      label="Titile"
                      name="title"
                      value={values.title ? values.title : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title ? errors.title : ''}
                      error={touched.title && Boolean(errors.title)}
                    ></TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="updates_description"
                      fullWidth
                      label="Description"
                      name="description"
                      value={values.description ? values.description : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.description ? errors.description : ''}
                      error={touched.description && Boolean(errors.description)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="updates_tag_label">Tag</InputLabel>
                    <Select
                      id="updates_tag"
                      labelId="updates_tag_label"
                      name="tag"
                      value={values.tag ? values.tag : ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.tag && Boolean(errors.tag)}
                    >
                      {/* Define your options here */}
                      <MenuItem value="released">Released</MenuItem>
                      <MenuItem value="upcoming">Upcoming</MenuItem>
                    </Select>
                    {touched.tag && Boolean(errors.tag) && (
                      <FormHelperText error>{errors.tag}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && <CircularProgress size={20} />}
                      {!isSubmitting &&
                        (props.initialValues.title ? 'Edit' : 'Create')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
const UpcomingUpdatesCreateForm = (props: UpcomingUpdatesCreateFormProp) => {
  const { handleSubmit, initialValues } = props;
  const FormElement = withFormik({
    mapPropsToValues: () => ({
      id: initialValues?.id ?? '',
      title: initialValues?.title ?? '',
      description: initialValues?.description ?? '',
      tag: initialValues?.tag ?? ''
    }),
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title Required'),
      description: Yup.string().required('Description Required'),
      tag: Yup.string().required('Tag Required')
    }),
    handleSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      handleSubmit(values, setSubmitting, setErrors);
    }
  })(CForm);
  return <FormElement />;
};

export default UpcomingUpdatesCreateForm;
