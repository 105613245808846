import {CircularProgress, ListItem, ListItemAvatar, ListItemText, Typography} from '@mui/material';
import {CustomerDatabase, CustomerDatabaseListProps} from "./types";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import * as React from "react";
import Text from "../../../components/Text";

const CustomerDatabaseList = (customerDatabaseInfoProps: CustomerDatabaseListProps) => {
    const getLabel = (customer: CustomerDatabase) => {
        let message = 'Loading';
        if (!customer.loading) {
            message = (customer.status) ? 'Migrated' : 'Error';
        }
        return message;
    }
    const getLabelComponent = (customer: CustomerDatabase) => {
        let component = <CircularProgress/>;
        if (!customer.loading) {
            component = (customer.status) ? <CheckBoxIcon/> : <CancelIcon/>;
        }
        return component;
    }
    return (
        <ListItem
            sx={{
                py: 2
            }}
        >
            <ListItemAvatar>
                {getLabelComponent(customerDatabaseInfoProps.customer)}
            </ListItemAvatar>
            <ListItemText
                primary={<Text color="black">{customerDatabaseInfoProps.customer.customer_name}</Text>}
                primaryTypographyProps={{
                    variant: 'body1',
                    fontWeight: 'bold',
                    color: 'textPrimary',
                    gutterBottom: true,
                    noWrap: true
                }}
                secondary={<Text color="error">{getLabel(customerDatabaseInfoProps.customer)}</Text>}
                secondaryTypographyProps={{variant: 'body2', noWrap: true}}
            />
            {!customerDatabaseInfoProps.customer.loading && <Typography variant="subtitle2" sx={{width: '75%'}}>
                {customerDatabaseInfoProps.customer.message}
            </Typography>}
        </ListItem>
    );
}

export default CustomerDatabaseList;