import {Card, Container, Grid, Link} from '@mui/material';
import DataTable from "../../../components/DataTable";
import {CustomerData} from "../../../types/Customer";
import ContainButton from "../../../components/DataTable/ContainButton";
import ActionButton from "../../../components/DataTable/ActionButton";
import {DataTableAction} from "../../../components/DataTable/type";
import { Subscriptions } from '@mui/icons-material';

function CustomerListDataTable() {
    const actions: DataTableAction[] = [
        {type: 'edit', to: '/customers/:id/edit' },
        {type: 'delete', to: 'admin/customers/:id/delete' },
        { type: 'subscription', to: '/customers/subscription/:id'}
    ];
    const columns = [
        {title: 'Id', field: 'id'},
        {title: 'Name', field: 'name'},
        {title: 'Email', field: 'email'},
        {title: 'Phone No.', field: 'mobile'},
        {title: 'Subscription Status', field: 'status'},
        {
            title: 'Subdomain',
            render: (rowData: CustomerData) => {
                return (<Link href={'http://'+rowData.subdomain+'.urride.online'} target="_blank">{rowData.subdomain}.urride.online</Link>);
            }
        },
        {title: 'Domain', field: 'domain'},
        // {
        //     title: 'Product',
        //     render: (rowData: CustomerData) => {
        //         return (<ContainButton contain={Boolean(rowData.product)}
        //                                buttonText="Add Product"
        //                                buttonHref={"/customers/" + rowData.id + "/product"}
        //                                textHref={"/customers/" + rowData.id + "/product"}
        //                                text={rowData.product?.name ?? ''}/>);
        //     }
        // },
        {
            title: 'Database',
            render: (rowData: CustomerData) => {
                if(rowData.db_name) {
                    return rowData.db_name;
                } else {
                    return (<ContainButton contain={Boolean(rowData.product)}
                                           buttonText="Generate Database"
                                           buttonHref={"/customers/" + rowData.id + "/database"}
                                           textHref={"/customers/" + rowData.id + "/database"}
                                           text={rowData.db_name ?? ''}/>);
                }

            }
        },
        {
            title: 'Action',
            render: (rowData: CustomerData) => {
                return (<ActionButton actions={actions} id={rowData.id} />);
            }
        }
    ]
    const url = 'admin/customers';

    return (
        <Container maxWidth="xl">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item md={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                            exportButton={true}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default CustomerListDataTable;
