import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    CircularProgress
} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { ProductConfigInput } from "../../../types/Customer";
import { FormSubmit } from "../../../types/Form";


type CustomerProductConfigFormProp = {
    handleSubmit: FormSubmit,
    initialValues?: ProductConfigInput
}
const CForm = (props: FormikProps<ProductConfigInput>) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props;

    return (
        <div>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={2} pt={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        <Card>
                            <CardHeader title='Product Config  Form' />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="distance_unit"
                                            select
                                            fullWidth
                                            label="Distance Unit"
                                            name="distance_unit"
                                            value={values.distance_unit || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.distance_unit ? errors.distance_unit : ""}
                                            error={touched.distance_unit && Boolean(errors.distance_unit)}
                                        >

                                            <MenuItem value="mile"> Mile </MenuItem>
                                            <MenuItem value="km"> Kilometer </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="currency"
                                            select
                                            fullWidth
                                            label="Currency Unit"
                                            name="currency"
                                            value={values.currency || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.currency ? errors.currency : ""}
                                            error={touched.currency && Boolean(errors.currency)}
                                        >

                                            <MenuItem value="dollar_us">US Dollar ($) </MenuItem>
                                            <MenuItem value="dollar_aus">AUS Dollar ($) </MenuItem>
                                            <MenuItem value="pound"> Pound (£) </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="accessed-countries"
                                            fullWidth
                                            label="Accessed Countries"
                                            name="accessed_countries"
                                            value={values.accessed_countries || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            helperText={touched.accessed_countries ? errors.accessed_countries : ""}
                                            error={touched.accessed_countries && Boolean(errors.accessed_countries)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button variant="contained" type="submit" disabled={isSubmitting}>
                                            {isSubmitting && <CircularProgress size={20} />}
                                            {!isSubmitting && 'Update'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
const CustomerProductConfigForm = (props: CustomerProductConfigFormProp) => {
    const { handleSubmit, initialValues } = props;
    const FormElement = withFormik({
        mapPropsToValues: () => ({
            distance_unit: initialValues?.distance_unit ?? 'mile',
            currency: initialValues?.currency ?? 'dollar_us',
            rate_per_minute_included: initialValues?.rate_per_minute_included ?? 'no',
            accessed_countries: initialValues?.accessed_countries ?? '',
        }),
        validationSchema: Yup.object().shape({
            currency: Yup.string().required("Required"),
            distance_unit: Yup.string().required("Required"),
        }),
        handleSubmit: (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            handleSubmit(values, setSubmitting, setErrors)
        },
    })(CForm);
    return <FormElement />
}

export default CustomerProductConfigForm;