import {Suspense, lazy, LazyExoticComponent} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import AdminLayout from 'src/layouts/AdminLayout';
import AuthLayout from 'src/layouts/AuthLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import {CommonRoutes} from "./routes/Common";
import {CustomerRoutes} from "./routes/CustomerRoutes";
import {ZoneRoutes} from "./routes/ZoneRoutes";

const Loader = (Component:  LazyExoticComponent<() => JSX.Element>) => (props: object) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

const Overview = Loader(lazy(() => import('src/content/overview')));

const ChildrenRoutes = [ ... CommonRoutes, ... CustomerRoutes, ... ZoneRoutes];

const routes = (isLoggedIn: boolean): RouteObject[] => [
    {
        path: '',
        element: isLoggedIn ? <Navigate to="/dashboard"/> : <AuthLayout/>,
        children: [
            {
                path: '/login',
                element: <Overview/>
            },
            {
                path: '/',
                element: <Navigate to="/login"/>
            },
        ]
    },
    {
        path: '',
        element: isLoggedIn ? <AdminLayout/> : <Navigate to="/login"/>,
        children: ChildrenRoutes
    },
];

export default routes;
