import { ReactEventHandler, forwardRef, useEffect, useState } from 'react';
import MaterialTable, { Icons } from 'material-table';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { get } from '../../services/ApiService';
import { DatatableData, DatatableProps } from './type';
import './table.css';

export const DataTable = ({
  columns,
  url,
  title,
  cusParams,
  exportButton
}: DatatableProps<object>) => {
  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultFilterData = {
    perPage: 10,
    search: '',
    url: url
  };

  const paginationData: DatatableData = {
    current_page: 0,
    data: [],
    first_page_url: null,
    from: null,
    last_page: 0,
    last_page_url: '',
    links: [],
    next_page_url: null,
    path: url,
    per_page: 10,
    prev_page_url: null,
    to: null,
    total: 0
  };

  const [tableData, setTableData] = useState(paginationData);
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [isLoading, setIsLoading] = useState(true);

  const changeRowsPerPage = (data: number) => {
    setFilterData({ ...filterData, perPage: data });
  };
  const handleSearchChange = (data: string) => {
    setFilterData({ ...filterData, search: data });
  };
  useEffect(() => {
    const searchData = filterData.search != '' ? filterData.search : null;
    const params: any = {
      length: filterData.perPage,
      search: searchData
    };
    if (cusParams && cusParams.length > 0) {
      cusParams.forEach((p) => {
        params[p.name] = p.value;
      });
    }
    get(paginationData.path, params).then((res) => {
      setTableData(res.data);
      setIsLoading(false);
    });
  }, [filterData, cusParams]);

  const handlePageChange = (event: number, page: number) => {
    setIsLoading(true);
    get(paginationData.path, { length: page, page: event }).then((res) => {
      setTableData(res.data);
      setIsLoading(false);
    });
  };

  return (
      <MaterialTable
        columns={columns}
        isLoading={isLoading}
        data={tableData.data}
        page={tableData.current_page}
        totalCount={tableData.total}
        icons={tableIcons}
        onChangePage={(event, page) => handlePageChange(event, page)}
        // onChangeRowsPerPage={changeRowsPerPage}
        onSearchChange={handleSearchChange}
        title={title}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500, 1000, 10000],
          exportButton: exportButton,
          loadingType: 'linear'
        }}
      />
  );
};

export default DataTable;
