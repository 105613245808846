import {Card, Container, Grid} from '@mui/material';
import DataTable from "../../../components/DataTable";
import {CustomerData} from "../../../types/Customer";
import ActionButton from "../../../components/DataTable/ActionButton";
import {DataTableAction} from "../../../components/DataTable/type";

function ProductServiceListDataTable() {
    const actions: DataTableAction[] = [{type: 'edit', to: '/product-services/:id/edit'}];
    const columns = [
        {title: 'Id', field: 'id', width: '10%'},
        {title: 'Name', field: 'name'},
        {title: 'Info', field: 'info', width: '30%'},
        {title: 'Reference', field: 'reference'},
        {title: 'Order', field: 'sort'},
        {
            title: 'Action',
            render: (rowData: CustomerData) => {
                return (<ActionButton actions={actions} id={rowData.id}/>);
            }
        }
    ]
    const url = 'admin/product-services';

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Card>
                        <DataTable
                            columns={columns}
                            url={url}
                            title={''}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>

    );
}

export default ProductServiceListDataTable;
