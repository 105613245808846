import CustomerList from "../content/customers/CustomerList";
import CustomerCreate from "../content/customers/CustomerCreate";
import CustomerProduct from "../content/customers/CutomerProduct";
import CustomerEdit from "../content/customers/CustomerEdit";
import CustomerProductEdit from "../content/customers/CutomerProductEdit";
import CustomerDatabaseMigration from "../content/customers/CustomerDatabaseMigration";
import CustomerProductSetting from "../content/customers/CutomerProductSetting";
import CustomerProductConfigEdit from "../content/customers/CutomerProductConfigEdit";
import CustomerSubscription from "../content/customers/CustomerSubscription";

export const CustomerRoutes = [
    {
        path: 'customers',
        element: <CustomerList />
    },
    {
        path: 'customers/subscription/:id',
        element: <CustomerSubscription />
    },
    {
        path: 'customers/create',
        element: <CustomerCreate />
    },
    {
        path: 'customers/database-migration',
        element: <CustomerDatabaseMigration />
    },
    {
        path: 'customers/:id/product',
        element: <CustomerProduct />
    },
    {
        path: 'customers/:id/product-edit',
        element: <CustomerProductEdit />
    },
    {
        path: 'customers/:id/edit',
        element: <CustomerEdit />
    },
    {
        path: 'products/:id/setting',
        element: <CustomerProductSetting />
    },
    {
        path: 'products/:id/config-edit',
        element: <CustomerProductConfigEdit />
    }
];