import {useRoutes} from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {isLoggedIn} from "./services/AuthService";
import 'wicket/wicket-gmap3';

function App() {
    const isLogged = isLoggedIn();
    const content = useRoutes(router(isLogged));

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline/>
                {content}
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
