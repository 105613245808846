import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {FormSetError, FormSubmitting} from "../../../../types/Form";
import {ZoneData, ZoneInputData} from "../../../../types/zone";
import {get, post} from "../../../../services/ApiService";
import {PageHeader} from "../../../../components/PageHeader";
import {useEffect, useState} from "react";
import {showValidationError} from "../../../../services/FormService";
import AreaCreateForm from "../AreaCreate/AreaCreateForm";

const defaultInitialValues: ZoneData = {
    id: '',
    name: '',
    boundingbox: undefined,
    country_id: '',
    state_id: '',
    polygon_data: undefined
}
function ZoneEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/zones',
            title: 'Zones List',
        }
    ]

    useEffect(() => {
        get('admin/zones/' + id).then((res) => {
            setInitialValues(res.data);
        })
    }, [])

    const handleSubmit = (data: ZoneInputData, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        delete data.polygon_data;

        post('admin/zones/' + id, data).then(() => {
            setSubmitting(false);
            navigate('/zones')
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Zone Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Zone Edit" showButton={false}/>
            <AreaCreateForm handleSubmit={handleSubmit} initialValues={initialValues}/>
        </>
    );
}

export default ZoneEdit;
