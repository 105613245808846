import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { BaseTextFieldProps } from "@mui/material/TextField";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import IntlTelInput, {
    CountryData,
    IntlTelInputProps
} from "react-intl-tel-input";

import IntlTelFieldUtils from "./IntlTelFieldUtils";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {FormSetFieldValue} from "../../types/Form";

export interface IntlTelFieldPropsSizeOverrides {}

export type BaseIntlTelFieldProps = Pick<
    BaseTextFieldProps,
    | "color"
    | "error"
    | "FormHelperTextProps"
    | "helperText"
    | "InputLabelProps"
    | "label"
    | "required"
    | "size"
    | "variant"
    >;

export const handelPhoneNumberChange = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string, name: string, setFieldValue: FormSetFieldValue) => {
    setFieldValue(name + '_', value);
    setFieldValue(name, '+' + selectedCountryData.dialCode + value);
}

export const handelCountrySelect = (value: string, selectedCountryData: CountryData, fullNumber: string, isValid: boolean, name: string, setFieldValue: FormSetFieldValue) => {
    setFieldValue(name + '_', value);
     setFieldValue(name, '+' + selectedCountryData.dialCode + value);
}

const IntlTelField = React.forwardRef<
    IntlTelInput,
    IntlTelInputProps & BaseIntlTelFieldProps & TextFieldProps & {labelClassName ?: string}
    >((props, ref) => {
    const {
        color = "primary",
        error,
        FormHelperTextProps,
        helperText,
        InputLabelProps,
        label,
        required,
        size,
        variant = "outlined",
        ...intlTelInputProps
    } = props;
    const {
        disabled,
        fieldId: id,
        containerClassName: containerCss = "",
        inputClassName: inputCss = "tele-input-css",
        labelClassName: labelCss = "tele-label-css"
    } = intlTelInputProps;

    const [filled, setFilled] = useState(false);
    const [focused, setFocused] = useState(false);

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    const telInputRef = useRef<HTMLInputElement | null | undefined>(undefined);
    const intlTelInputRef = useRef<IntlTelInput | null>(null);
    const fieldsetRef = useRef<HTMLFieldSetElement | null>(null);
    const setIntlTelInputRef = (instance: IntlTelInput | null, value: string| null) => {

        intlTelInputRef.current = instance;
        if (typeof ref === "function") {
            ref(instance);
        } else if (ref && "current" in ref) {
            ref.current = instance;
        }
    };

    useEffect(() => {
        telInputRef.current = intlTelInputRef.current?.tel;
        IntlTelFieldUtils.insertAfter(telInputRef.current, fieldsetRef.current);
    }, []);
    //#endregion

    const handlePhoneNumberFocus = (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        extension: string,
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        setFocused(true);

        if (!props.onPhoneNumberFocus) {
            return;
        }

        props.onPhoneNumberFocus(
            isValid,
            value,
            selectedCountryData,
            fullNumber,
            extension,
            event
        );
    };
    const handlePhoneNumberBlur = (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        extension: string,
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        setFocused(false);
        if (!props.onPhoneNumberBlur) {
            return;
        }

        props.onPhoneNumberBlur(
            isValid,
            value,
            selectedCountryData,
            fullNumber,
            extension,
            event
        );
    };

    const handlePhoneNumberChange = (
        isValid: boolean,
        value: string,
        selectedCountryData: CountryData,
        fullNumber: string,
        extension: string
    ) => {
        setFilled(!!value);

        if (!props.onPhoneNumberChange) {
            return;
        }

        props.onPhoneNumberChange(
            isValid,
            value,
            selectedCountryData,
            fullNumber,
            extension
        );
    };

    const Variant = IntlTelFieldUtils.capitalizeFirstLetter(variant);
    const Color = IntlTelFieldUtils.capitalizeFirstLetter(color);
    const formControlClassName = clsx(
        "MuiTextField-root",
        "MuiIntlTelField-root"
    );
    const containerClassName = clsx(
        "intl-tel-input",
        `Mui${Variant}Input-root`,
        `Mui${Variant}IntlTelInput-root`, // Custom style
        "MuiInputBase-root",
        `MuiInputBase-color${Color}`,
        {
            "Mui-focused": focused || filled
        },
        "MuiInputBase-formControl",
        containerCss // Consumer overrides
    );
    const inputClassName = clsx(
        "form-control",
        `Mui${Variant}Input-input`,
        `Mui${Variant}IntlTelInput-input`, // Custom style
        "MuiInputBase-input",
        inputCss // Consumer overrides
    );
    return (
        <FormControl
            className={formControlClassName}
            disabled={disabled}
            error={error}
            focused={focused || filled}
            required={required}
            size={size}
        >
            {label && (
                <InputLabel className={labelCss} {...InputLabelProps} htmlFor={id} id={inputLabelId}>
                    {label}
                </InputLabel>
            )}
            <IntlTelInput
                {...(intlTelInputProps as IntlTelInputProps)}
                onPhoneNumberFocus={handlePhoneNumberFocus}
                onPhoneNumberBlur={handlePhoneNumberBlur}
                onPhoneNumberChange={handlePhoneNumberChange}
                ref={() => setIntlTelInputRef}
                containerClassName={containerClassName}
                inputClassName={inputClassName}
                placeholder=""
            />

            {helperText && (
                <FormHelperText id={helperTextId} {...FormHelperTextProps}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
});

export default IntlTelField;
