import {Button, IconButton} from "@mui/material";
import {useContext} from "react";
import {SidebarContext} from "../../../../contexts/SidebarContext";
import DehazeIcon from "@mui/icons-material/Dehaze";


function HeaderMenu() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  return (
    <>
        <IconButton color="primary" onClick={toggleSidebar}>
            <DehazeIcon />
        </IconButton>
    </>
  );
}

export default HeaderMenu;
