import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {get, post} from "../../../services/ApiService";
import {CustomerInputs} from "./types";
import {showValidationError} from "../../../services/FormService";
import {PageHeader} from "../../../components/PageHeader";
import CustomerEditForm from "./CustomerEditForm";
import {useEffect, useState} from "react";
import {FormSetError, FormSubmitting} from "../../../types/Form";

const defaultInitialValues = {
    name: '',
    email: '',
    mobile: '',
    mobile_: '',
    username:'',
    password:'',
    domain: '',
    ios_app_link: '',
    android_app_link: ''

}
function CustomerCreate() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]
    useEffect(() => {
        get('admin/customers/' + id).then((res) => {
            setInitialValues(res.data);
        })
    }, [])
    const handleLogin = (data: CustomerInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/customers/'+ id, data).then((res) => {
            setSubmitting(false);
            navigate('/customers');
        }).catch((error) => {
            showValidationError(error.response, setErrors, [{from: 'mobile', to: 'mobile_'}]);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Customer Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Customer Edit" showButton={false}/>
            <CustomerEditForm handleSubmit={handleLogin} initialValues={initialValues} />
        </>
    );
}

export default CustomerCreate;
