import { Helmet } from 'react-helmet-async';
import { PageHeader} from "../../../components/PageHeader";
import ProductServiceListDataTable from "./ProductServiceListDataTable";


function ProductServiceList() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]

    const headerButton =
        {
            href: '/product-services/create',
            title: 'Add Module',
        }
    return (
        <>
            <Helmet>
                <title>Module List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Module List" showButton={true} headerButton={headerButton}/>
            <ProductServiceListDataTable />
        </>
    );
}

export default ProductServiceList;
