import { Helmet } from 'react-helmet-async';
import {useNavigate, useParams} from "react-router";
import {get, post} from "../../../services/ApiService";
import {showValidationError} from "../../../services/FormService";
import {PageHeader} from "../../../components/PageHeader";
import {useEffect, useState} from "react";
import {FormSetError, FormSubmitting} from "../../../types/Form";
import ProductServiceForm from "../ProductServiceForm";
import {ProductServiceInputs} from "../ProductServiceCreate/types";

const defaultInitialValues = {
    name: '',
    info: '',
    reference: '',
    sort: 1,
    features: [{
        name: '',
        info: '',
        reference: '',
        sort: 1,
    }]
}
function ProductServiceEdit() {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    let {id} = useParams();
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/product-services',
            title: 'Module List',
        }
    ]
    useEffect(() => {
        get('admin/product-services/' + id).then((res) => {
            setInitialValues(res.data);
        })
    }, [])
    const handleLogin = (data: ProductServiceInputs, setSubmitting: FormSubmitting, setErrors: FormSetError) => {
        post('admin/product-services/'+ id, data).then((res) => {
            setSubmitting(false);
            navigate('/product-services');
        }).catch((error) => {
            showValidationError(error.response, setErrors);
            setSubmitting(false);
        });
    }

    return (
        <>
            <Helmet>
                <title>Module Edit</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Module Edit" showButton={false}/>
            <ProductServiceForm handleSubmit={handleLogin} initialValues={initialValues} isEdit={true} />
        </>
    );
}

export default ProductServiceEdit;
