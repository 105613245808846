import {Helmet} from 'react-helmet-async';
import {get, post} from "../../../services/ApiService";
import {PageHeader} from "../../../components/PageHeader";
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {CustomerDatabase, DatabaseStatus, migrationSetFunction} from "./types";
import CustomerDatabaseInfo from "./CustomerDatabaseInfo";
import CustomerDatabaseMigrationLoading from "./CustomerDatabaseMigrationLoading";

function CustomerDatabaseMigration() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        },
        {
            href: '/customers',
            title: 'Customer List',
        }
    ]
    const [databaseLoaderStatus, setDatabaseLoaderStatus] = useState<boolean>(false);
    const [databaseStatus, setDatabaseStatus] = useState<DatabaseStatus | null>(null);
    const [databaseCustomers, setDatabaseCustomers] = useState<CustomerDatabase[]>([]);
    const onMigrationClick = (setOpen: migrationSetFunction, setLoading: migrationSetFunction) => {
        setLoading(true);
        get('admin/customers-list-for-database').then((res: AxiosResponse<CustomerDatabase[]>) => {
            setDatabaseLoaderStatus(true);
            setLoading(false);
            setOpen(false);
            let fullData = res.data;
            setDatabaseCustomers(fullData);
            res.data.forEach((customer) => {
                post('admin/customers-database-migrate/' + customer.id, {})
                    .then((customerRes: AxiosResponse<CustomerDatabase>) => {
                        const data = fullData.map((cus) => {
                            if (cus.id == customer.id) {
                                cus.status = customerRes.data.status;
                                cus.message = customerRes.data.message;
                                cus.loading = false;
                            }
                            return cus;
                        });
                        setDatabaseCustomers(data);
                        fullData = data;
                    });
            })
        });
    }
    useEffect(() => {
        get('admin/customers-database-status').then((res: AxiosResponse<DatabaseStatus>) => {
            if(res.data.status) {
                setDatabaseStatus(res.data);
            }
        });

    }, [])

    return (
        <>
            <Helmet>
                <title>Customer Database Migration Status</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Customer Database Migration" showButton={false}/>
            <CustomerDatabaseInfo databaseInfo={databaseStatus} onMigrationClick={onMigrationClick}/>
            <CustomerDatabaseMigrationLoading customerDatabases={databaseCustomers} databaseLoaderStatus={databaseLoaderStatus}/>
        </>
    );
}

export default CustomerDatabaseMigration;
