import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {PageHeader} from "../../../../components/PageHeader";
import StateListDataTable from "./StateListDataTable";

const StateList = () => {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]
    const headerButton =
        {
            href: '/states/create',
            title: 'State Create',
        }
    return (
        <>
            <Helmet>
                <title>States List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} showButton={true} title="State List" headerButton={headerButton}/>
            <StateListDataTable/>

        </>
    );
}

export default StateList;
