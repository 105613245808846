import { Helmet } from 'react-helmet-async';
import {HeaderButton, PageHeader} from "../../../components/PageHeader";
import {useEffect, useState} from "react";
import {get} from "../../../services/ApiService";
import ProductSettingListDataTable from "./ProductSettingListDataTable";
import {AxiosResponse} from "axios";
import {CustomerData, ProductService, ProductServiceFeature} from "../../../types/Customer";


function ProductSettingList() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]
    const [features, setFeatures] = useState<ProductServiceFeature[]>([]);
    useEffect(() => {
        get('admin/setting-features').then((res: AxiosResponse<{ features: ProductServiceFeature[] }>) => {
            setFeatures(res.data.features);
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Product Setting List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Setting Submodule List" showButton={false} />
            <ProductSettingListDataTable features={features}/>
        </>
    );
}

export default ProductSettingList;
