import { Helmet } from 'react-helmet-async';
import { PageHeader} from "../../../components/PageHeader";
import UpcomingUpdatesDataTable from './UpcomingUpdatesDataTable';

function UpComingUpdatesList() {
    const breadcrumbs = [
        {
            href: '/dashboard',
            title: 'Home',
        }
    ]

    const headerButton =
        {
            href: '/upcoming-updates/create',
            title: 'Add Upcoming Updates',
        }
    return (
        <>
            <Helmet>
                <title>Upcoming Updates List</title>
            </Helmet>
            <PageHeader breadcrumbs={breadcrumbs} title="Upcoming Updates List" showButton={true} headerButton={headerButton}/>
            <UpcomingUpdatesDataTable />
        </>
    );
}
export default UpComingUpdatesList;
